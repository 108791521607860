<template>
  <div class="tba-mb-8">
    <div class="tba-flex tba-items-center tba-space-x-6">


      <!-- Neemt u bagage mee? Ja/Nee Selectie -->
      <div class="tba-flex-1 tba-max-w-[45%]">
        <div class="tba-flex tba-flex-col tba-gap-2">
          <!-- Vraagtekst -->
          <span
            class="tba-text-base tba-font-semibold tba-text-text-secondary tba-leading-tight tba-whitespace-nowrap tba-overflow-hidden tba-text-ellipsis">
            {{ translations[currentLanguage].AdditionalOptionsComponent.baggageQuestion }}
          </span>

          <!-- Controleer type button-stijl -->
          <div>
            <nav v-if="buttonType === 'slider'" class="tba-relative tba-flex tba-items-center tba-bg-switch-button-grey tba-rounded-xl tba-w-full tba-px-2 tba-py-1">
              <!-- Sliding Background for Active Selection -->
              <div
                class="tba-absolute tba-inset-y-1 tba-left-1 tba-w-[50%] tba-bg-hover tba-rounded-md tba-transition-transform tba-duration-300"
                :class="{ 'tba-translate-x-full': hasBaggage === 'yes' }">
              </div>

              <!-- Nee Button -->
              <button
                type="button"
                class="tba-relative tba-flex tba-items-center tba-justify-center tba-w-1/2 tba-h-8 tba-text-sm tba-font-medium tba-rounded-lg tba-z-10 tba-transition-colors tba-duration-300"
                :class="{
                  'tba-text-text-on-primary tba-font-semibold': hasBaggage === 'no',
                  'tba-text-text-on-primary': hasBaggage === 'yes'
                }"
                @click="hasBaggage = 'no'">
                {{ translations[currentLanguage].AdditionalOptionsComponent.noOption }}
              </button>

              <!-- Ja Button -->
              <button
                type="button"
                class="tba-relative tba-flex tba-items-center tba-justify-center tba-w-1/2 tba-h-8 tba-text-sm tba-font-medium tba-rounded-lg tba-z-10 tba-transition-colors tba-duration-300"
                :class="{
                  'tba-text-text-on-primary tba-font-semibold': hasBaggage === 'yes',
                  'tba-text-text-on-primary': hasBaggage === 'no'
                }"
                @click="hasBaggage = 'yes'">
                {{ translations[currentLanguage].AdditionalOptionsComponent.yesOption }}
              </button>
            </nav>

            <nav v-else class="tba-flex tba-gap-1 tba-w-full">
              <!-- Simpele Knoppen -->
              <button
                type="button"
                class="tba-relative tba-flex-1 tba-px-4 tba-py-2 tba-rounded-md tba-text-sm tba-font-medium tba-transition-all tba-duration-300 tba-text-center"
                :class="{
                  'tba-border tba-border-text-primary tba-text-text-secondary': hasBaggage === 'no',
                  'tba-border tba-border-gray-300 tba-text-text-secondary': hasBaggage !== 'no'
                }"
                @click="hasBaggage = 'no'"
              >
                {{ translations[currentLanguage].AdditionalOptionsComponent.noOption }}
                <span
                  v-if="hasBaggage === 'no'"
                  class="tba-absolute tba-top-1 tba-right-1 tba-w-2 tba-h-2 tba-bg-text-secondary tba-rounded-full"
                ></span>
              </button>

              <button
                type="button"
                class="tba-relative tba-flex-1 tba-px-4 tba-py-2 tba-rounded-md tba-text-sm tba-font-medium tba-transition-all tba-duration-300 tba-text-center"
                :class="{
                  'tba-border tba-border-text-primary tba-text-text-secondary': hasBaggage === 'yes',
                  'tba-border tba-border-gray-300 tba-text-text-secondary': hasBaggage !== 'yes'
                }"
                @click="hasBaggage = 'yes'"
              >
                {{ translations[currentLanguage].AdditionalOptionsComponent.yesOption }}
                <span
                  v-if="hasBaggage === 'yes'"
                  class="tba-absolute tba-top-1 tba-right-1 tba-w-2 tba-h-2 tba-bg-text-secondary tba-rounded-full"
                ></span>
              </button>
            </nav>


          </div>
        </div>
      </div>

      <!-- Verticale Scheidingslijn -->
      <div class="tba-border-l tba-border tba-border-color" style="height: 3rem;"></div>

      <!-- Aantal Personen -->
      <div class="tba-flex-1 tba-flex tba-justify-center">
        <div class="tba-flex tba-flex-col tba-items-center tba-gap-2 tba-w-full">
          <span
            class="tba-text-base tba-font-semibold tba-text-text-secondary tba-leading-tight tba-text-center tba-whitespace-nowrap tba-overflow-hidden tba-text-ellipsis">
            {{ translations[currentLanguage].AdditionalOptionsComponent.passengerCountLabel }}
          </span>

          <div class="tba-flex tba-items-center tba-justify-center">
            <i class="fas fa-user tba-text-xl tba-text-text-secondary tba-mr-2"></i>
            <button
              type="button"
              class="tba-border  tba-text-text-secondary tba-rounded-md tba-w-8 tba-h-8 tba-flex tba-items-center tba-justify-center tba-cursor-pointer tba-font-bold tba-text-lg"
              @click="adjustPassengerCount(-1)">
              -
            </button>
            <span class="tba-text-lg tba-text-text-primary tba-font-semibold tba-mx-2">{{ passengerCount }}</span>
            <button
              type="button"
              class="tba-border tba-border-border-color-light tba-text-text-secondary tba-rounded-md tba-w-8 tba-h-8 tba-flex tba-items-center tba-justify-center tba-cursor-pointer tba-font-bold tba-text-lg"
              @click="adjustPassengerCount(1)">
              +
            </button>
          </div>
        </div>
      </div>

    </div>

    <!-- Bagage Opties -->
    <transition name="slide-fade">
      <div v-show="hasBaggage === 'yes'" class="baggage-options-container tba-overflow-hidden tba-mt-4">
        <!-- Large Baggage -->
        <div class="tba-flex tba-justify-between tba-items-center tba-border tba-border-color tba-p-3 tba-rounded-lg tba-mb-4">
          <div class="tba-flex tba-items-center">
            <i class="fas fa-suitcase-rolling tba-text-xl tba-text-text-secondary tba-mr-3"></i>
            <div>
              <h3 class="tba-font-medium tba-text-md tba-text-text-primary">
                {{ translations[currentLanguage].AdditionalOptionsComponent.largeLuggage_title }}
              </h3>
              <p class="tba-text-sm tba-text-text-secondary">85cm x 55cm x 35cm</p>
            </div>
          </div>
          <div class="tba-flex tba-items-center tba-space-x-2">
            <button
              type="button"
              class="tba-border tba-border-color tba-text-text-secondary tba-rounded-md tba-w-8 tba-h-8 tba-flex tba-items-center tba-justify-center tba-cursor-pointer tba-font-bold tba-text-lg"
              @click="adjustBaggageCount('large', -1)">
              -
            </button>
            <span class="tba-text-md tba-text-text-primary tba-font-semibold">{{ largeLuggageCount }}</span>
            <button
              type="button"
              class="tba-border tba-border-color tba-text-text-secondary tba-rounded-md tba-w-8 tba-h-8 tba-flex tba-items-center tba-justify-center tba-cursor-pointer tba-font-bold tba-text-lg"
              @click="adjustBaggageCount('large', 1)">
              +
            </button>
          </div>
        </div>

        <!-- Small Baggage -->
        <div class="tba-flex tba-justify-between tba-items-center tba-border tba-border-color tba-p-3 tba-rounded-lg">
          <div class="tba-flex tba-items-center">
            <i class="fas fa-briefcase tba-text-xl tba-text-text-secondary tba-mr-3"></i>
            <div>
              <h3 class="tba-font-medium tba-text-md tba-text-text-primary">
                {{ translations[currentLanguage].AdditionalOptionsComponent.smallLuggage_title }}
              </h3>
              <p class="tba-text-sm tba-text-text-secondary">55cm x 45cm x 25cm</p>
            </div>
          </div>
          <div class="tba-flex tba-items-center tba-space-x-2">
            <button
              type="button"
              class="tba-border tba-border-color tba-text-text-secondary tba-rounded-md tba-w-8 tba-h-8 tba-flex tba-items-center tba-justify-center tba-cursor-pointer tba-font-bold tba-text-lg"
              @click="adjustBaggageCount('small', -1)">
              -
            </button>
            <span class="tba-text-md tba-text-text-primary tba-font-semibold">{{ smallLuggageCount }}</span>
            <button
              type="button"
              class="tba-border tba-border-color tba-text-text-secondary tba-rounded-md tba-w-8 tba-h-8 tba-flex tba-items-center tba-justify-center tba-cursor-pointer tba-font-bold tba-text-lg"
              @click="adjustBaggageCount('small', 1)">
              +
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>



<script>
import { ref,  watch, inject } from 'vue';

export default {
  emits: ['updateOptions'],
  props: {
    buttonType: {
      type: String,
      required: true, // Zorg ervoor dat de prop verplicht is
    },
  },
  setup(_, { emit }) {
    const currentLanguage = inject("currentLanguage"); 
    const translations = inject("translations");


    console.log("Current Language:", currentLanguage);
    console.log("Translations:", translations);

    const hasBaggage = ref('no');
    const largeLuggageCount = ref(0);
    const smallLuggageCount = ref(0);
    const passengerCount = ref(1);

    const adjustBaggageCount = (type, change) => {
      if (type === 'large') {
        largeLuggageCount.value = Math.max(0, largeLuggageCount.value + change);
      } else if (type === 'small') {
        smallLuggageCount.value = Math.max(0, smallLuggageCount.value + change);
      }
      emitUpdate();
    };

    const adjustPassengerCount = (change) => {
      passengerCount.value = Math.max(1, passengerCount.value + change);
      emitUpdate();
    };

    const updateHasBaggage = (value) => {
      hasBaggage.value = value;
      emitUpdate();
    };

    const emitUpdate = () => {
      emit('updateOptions', {
        hasBaggage: hasBaggage.value,
        largeLuggageCount: largeLuggageCount.value,
        smallLuggageCount: smallLuggageCount.value,
        passengerCount: passengerCount.value,
      });
    };

    watch([hasBaggage, largeLuggageCount, smallLuggageCount, passengerCount], emitUpdate);

    return {
      hasBaggage,
      largeLuggageCount,
      smallLuggageCount,
      passengerCount,
      adjustBaggageCount,
      adjustPassengerCount,
      updateHasBaggage,
      currentLanguage,
      translations,
    };
  },
};
</script>


<style scoped>

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
  overflow: hidden;
}
.slide-fade-enter-from, .slide-fade-leave-to {
  max-height: 0;
  opacity: 0;
}
.slide-fade-enter-to, .slide-fade-leave-from {
  max-height: 500px;
  opacity: 1;
}

.baggage-item {
  border: 1px solid #D1D5DB !important; /* Gebruik dezelfde kleur als in de class border-gray-300 */
  padding: 0.75rem !important;
  border-radius: 0.5rem !important; /* Ronding consistent houden */
  margin-bottom: 1rem !important;
}

</style>
