<template>
  <div class="tba-faretype-container tba-max-w-full tba-mx-auto tba-p-5 tba-bg-primary-bg tba-rounded-lg">
    <span class="tba-text-xl tba-text-text-primary tba-font-semibold ">
      {{ translations[currentLanguage]?.FareTypeComponent?.selectTaxiLabel || "Label ontbreekt" }}
    </span>

    <!-- Loader weergave -->
    <div v-if="loading" class="loader-container tba-flex tba-flex-col tba-items-center tba-text-gray-800 tba-text-base">
      <div class="loader tba-border-4 tba-border-gray-200 tba-border-l-blue-400 tba-rounded-full tba-w-10 tba-h-10 tba-animate-spin"></div>
      <p>{{ translations[currentLanguage]?.FareTypeComponent?.loadingMessage || "Laden..." }}</p>
    </div>

    <!-- Fare types weergave -->
    <div v-else class="faretypes tba-mt-2 tba-flex tba-flex-col tba-gap-5">
      <label
        v-for="type in fareTypes"
        :key="type.value"
        class="faretype tba-flex tba-items-center tba-border-2 tba-rounded-lg tba-cursor-pointer tba-transition-transform tba-transform tba-hover:scale-105"
        :class="{ 'tba-border-black': selectedFareType === type.value, 'tba-border-transparent': selectedFareType !== type.value }"
      >
        <input
          type="radio"
          name="fareType"
          :value="type.value"
          v-model="selectedFareType"
          class="tba-hidden"
        />
        <div class="faretype-content tba-flex tba-flex-row tba-items-center tba-flex-grow tba-justify-between tba-p-2">
          <div class="faretype-left tba-flex tba-flex-col tba-items-center tba-mr-5">
            <div class="faretype-title tba-text-lg tba-font-bold tba-text-center tba-mb-2">
              {{ translations[currentLanguage]?.FareTypeComponent?.[`fareTypes_${type.value}_title`] || "Onbekende titel" }}
            </div>
            <div class="faretype-image">
              <img :src="type.image" loading="lazy" class="tba-w-24 tba-h-auto tba-rounded-lg" />
            </div>
          </div>
          <div class="faretype-details tba-text-sm tba-text-text-primary tba-text-left tba-mr-auto">
            <ul class="tba-list-inside">
              <li
                v-for="(detail, index) in [1, 2, 3]"
                :key="index"
                class="tba-mb-1"
              >
                {{
                  translations[currentLanguage]?.FareTypeComponent?.[
                    `fareTypes_${type.value}_details_${index + 1}`
                  ] || `Detail ${index + 1} ontbreekt`
                }}
              </li>
            </ul>
          </div>
          <div class="faretype-price tba-text-xl tba-font-bold tba-text-right tba-flex tba-flex-col tba-items-end">
            <span v-if="type.showStandard" class="tba-text-red-600 tba-line-through tba-text-lg">
              €{{ type.standardPrice }}
            </span>
            <span class="tba-text-2xl">€{{ type.discountedPrice }}</span>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>




<script>
import { ref, watch, inject, onMounted, reactive } from "vue";

export default {
  props: {
    distance: Number,
    duration: Number,
    pickupCity: String,
    destinationCity: String,
    destinationTwoCity: String,
    destinationThreeCity: String,
    dateOption: String,
    config: Object,
  },
  emits: ["fareSelected"],
  setup(props, { emit }) {
    const currentLanguage = inject("currentLanguage", "nl");
    const translations = inject("translations");

    const loading = ref(true);
    const selectedFareType = ref("");

    const fareTypes = reactive([
      {
        value: "standaard",
        image: "assets/images/standaard-airport-schiphol.webp",
        standardPrice: null,
        discountedPrice: null,
        showStandard: false,
      },
      {
        value: "bus",
        image: "assets/images/taxibus-airport-schiphol.webp",
        standardPrice: null,
        discountedPrice: null,
        showStandard: false,
      },
    ]);


    const calculateFare = (type) => {
      const standardRates = {
        standaard: { basePrice: 3.6, pricePerKm: 2.65, pricePerMinute: 0.44 },
        bus: { basePrice: 7.33, pricePerKm: 3.34, pricePerMinute: 0.49 },
      };
      const fareTypeConfig = props.config?.fareTypes?.[type] || {};
      const standardRate = standardRates[type];

      if (!fareTypeConfig || !standardRate) return { standardPrice: 0, discountedPrice: 0, showStandard: false };

      const standardPrice =
        standardRate.basePrice + props.distance * standardRate.pricePerKm + props.duration * standardRate.pricePerMinute;

      let companyPrice =
        fareTypeConfig.basePrice + props.distance * fareTypeConfig.pricePerKm + props.duration * fareTypeConfig.pricePerMinute;

      const locationMinimum = fareTypeConfig.locationSpecificMinimums?.find((rule) =>
        [props.pickupCity, props.destinationCity, props.destinationTwoCity, props.destinationThreeCity].includes(rule.location)
      );
      if (locationMinimum) {
        companyPrice = Math.max(companyPrice, locationMinimum.minimumPrice);
      }

      const locationDiscount = fareTypeConfig.locationSpecificDiscounts?.find((rule) =>
        [props.pickupCity, props.destinationCity, props.destinationTwoCity, props.destinationThreeCity].includes(rule.location)
      );
      if (locationDiscount && props.distance > locationDiscount.thresholdKm) {
        companyPrice -= companyPrice * locationDiscount.discountRate;
      }

      fareTypeConfig.discountRules?.forEach((rule) => {
        if (props.distance > rule.thresholdKm) {
          companyPrice -= companyPrice * rule.discountRate;
        }
      });

      companyPrice = Math.max(companyPrice, fareTypeConfig.minimumPrice);
      const showStandard = companyPrice < standardPrice;

      return {
        standardPrice: Math.ceil(standardPrice),
        discountedPrice: Math.ceil(companyPrice),
        showStandard,
      };
    };

    const handleCalculateFare = () => {
      loading.value = true;
      selectedFareType.value = "";

      setTimeout(() => {
        fareTypes.forEach((type) => {
          const fares = calculateFare(type.value);
          type.standardPrice = fares.standardPrice;
          type.discountedPrice = fares.discountedPrice;
          type.showStandard = fares.showStandard;
        });
        loading.value = false;
      }, 2000);
    };

    watch(() => [props.distance, props.duration], handleCalculateFare, { immediate: true });
    watch(selectedFareType, (newValue) => {
      const selectedType = fareTypes.find((type) => type.value === newValue);
      if (selectedType) {
        emit("fareSelected", {
          type: selectedType.value,
          price: selectedType.discountedPrice || selectedType.standardPrice,
        });
      }
    });

    onMounted(() => {
      if (props.distance && props.duration) {
        handleCalculateFare();
      }
    });

    return {
      loading,
      selectedFareType,
      fareTypes,
      currentLanguage,
      translations
    };
  },
};
</script>





<style scoped>
/* Alleen de loader animatie blijft hier */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
