export default {
  pickup_areas: {
    Amsterdam: {
      minimumReservationTime: 0,
      isPrimaryArea: true,
      onlyToPrimaryArea: false,
    },
    Amstelveen: {
      minimumReservationTime: 15,
      isPrimaryArea: false,
      onlyToPrimaryArea: false,
    },
    Diemen: {
      minimumReservationTime: 10,
      isPrimaryArea: false,
      onlyToPrimaryArea: true,
    },
    Schiphol: {
      minimumReservationTime: 5,
      isPrimaryArea: true,
      onlyToPrimaryArea: false,
    },
    Badhoevedorp: {
      minimumReservationTime: 20,
      isPrimaryArea: false,
      onlyToPrimaryArea: false,
    },
  },  
  fareTypes: {
    standaard: {
      basePrice: 4.02,
      pricePerKm: 2.96,
      pricePerMinute: 0.49,
      minimumPrice: 20,
      discountRules: [
        { thresholdKm: 20, discountRate: 0.1 },
        { thresholdKm: 50, discountRate: 0.15 },
      ],
      locationSpecificMinimums: [
        { location: "Schiphol", minimumPrice: 30 },
      ],
      locationSpecificDiscounts: [
        { location: "Schiphol", discountRate: 0.15, thresholdKm: 10 },
      ],
    },
    bus: {
      basePrice: 8.17,
      pricePerKm: 3.72,
      pricePerMinute: 0.55,
      minimumPrice: 30,
      discountRules: [
        { thresholdKm: 20, discountRate: 0.1 },
        { thresholdKm: 50, discountRate: 0.2 },
      ],
      locationSpecificMinimums: [
        { location: "Schiphol", minimumPrice: 35 },
      ],
      locationSpecificDiscounts: [
        { location: "Schiphol", discountRate: 0.15, thresholdKm: 10 },
      ],
    },
  },
};
