<template>
  <div
    v-if="config"
    class="tba-app-wrapper-desktop tba-min-h-screen tba-bg-cover tba-bg-center tba-relative"
  >

    <div class="tba-components-container ">

      <!-- Left side: text and reviews -->
      <div class="tba-left-section" :class="{ 'fade-out': showMap }">
        <div class="tba-intro-text">
          <h1 class="tba-headline tba-text-white">{{ config.formSettings.title || 'Welcome to Our Taxi Service' }}</h1>
          <p class="tba-subtext tba-text-white">{{ config.formSettings.subtitle || 'Reliable rides, excellent service, and unbeatable comfort!' }}</p>
        </div>
        
        <ReviewsComponent v-if="config.formSettings.showReviews" :isMobile="isMobile" />
      </div>

      <!-- Right side: Form, Map, and Fare Type -->
      <div :class="{ 'tba-right-section': true, 'expanded': showMap }">
        <div class="tba-form-wrapper">
          <RideDetailsComponent
            :googleLoaded="googleLoaded"
            :config="config"
            @calculateRoute="handleCalculateRoute"
            @updateAddress="handleAddressUpdate"
            @resetFareType="resetFareTypeComponent"
            @resetMap="resetMapComponent"                  
            @resetCustomerInfo="resetCustomerInfoComponent" 
            @resetRideOverview="resetRideOverviewComponent"
            @updateDate="updateDateFromRideDetails"
            @updateOptions="updateRideDetails"
          />
        </div>

        <div class="tba-map-fare-container">
          <MapComponent
            ref="mapComponent"
            v-show="showMap"
            :googleLoaded="googleLoaded"
            :pickup="pickup"
            :destination="destination"
            :destinationTwo="destinationTwo"
            :destinationThree="destinationThree"
            @routeCalculated="updateDistanceDuration"
            class="tba-map-container"
          />

          <FareTypeComponent
            v-if="showFareTypes"
            :distance="distance"
            :duration="duration"
            :pickupCity="pickupCity"
            :destinationCity="destinationCity"
            :destinationTwoCity="destinationTwoCity"
            :destinationThreeCity="destinationThreeCity"
            :dateOption="dateOption"
            :config="config"
            @fareSelected="handleFareSelected"
            class="tba-faretype-container"
          />
        </div>

        <transition name="slide-fade">
          <div v-if="showCustomerInfo" class="tba-customer-info-wrapper">
            <CustomerInfoComponent
              @customerInfoChanged="updateCustomerInfo"
              @customerInfoSubmitted="handleCustomerInfo"
            />
          </div>
        </transition>

        <transition name="slide-fade">
          <div v-if="showRideOverview" class="tba-ride-overview-wrapper">
            <RideOverviewComponent
              :pickupAddress="pickup"
              :pickupCity="pickupCity"
              :pickupSublocality="pickupSublocality"
              :destinationAddress="destination"
              :destinationCity="destinationCity"
              :destinationSublocality="destinationSublocality"
              :destinationTwo="destinationTwo"
              :destinationTwoCity="destinationTwoCity"
              :destinationTwoSublocality="destinationTwoSublocality"
              :destinationThree="destinationThree"
              :destinationThreeCity="destinationThreeCity"
              :destinationThreeSublocality="destinationThreeSublocality"
              :distance="distance"
              :duration="duration"
              :fareType="selectedFareType"
              :dateAndTime="pickupTime"
              :price="calculatedPrice"
              :customerInfo="customerInfo"
              :luggage="luggage"
              :passengerCount="passengerCount"
              :hasBaggage="hasBaggage"
              :isReservation="isReservation"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
  
  <div v-else class="loading-screen">
    <div class="spinner"></div>
    <p>Even geduld, de gegevens worden geladen...</p>
  </div>


</template>



<script>
import { ref, computed, onMounted } from 'vue';
import RideDetailsComponent from './components/RideDetailsComponent.vue';
import MapComponent from './components/MapComponent.vue';
import FareTypeComponent from './components/FareTypeComponent.vue';
import CustomerInfoComponent from './components/CustomerInfoComponent.vue';
import RideOverviewComponent from './components/RideOverviewComponent.vue';
import ReviewsComponent from './components/ReviewsComponent.vue';

export default {
  components: {
    RideDetailsComponent,
    MapComponent,
    FareTypeComponent,
    CustomerInfoComponent,
    RideOverviewComponent,
    ReviewsComponent,
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const googleLoaded = ref(false);
    const pickup = ref('');
    const pickupCity = ref('');
    const pickupSublocality = ref('');
    const destination = ref('');
    const destinationCity = ref('');
    const destinationSublocality = ref('');
    const destinationTwo = ref(''); 
    const destinationTwoCity = ref(''); 
    const destinationTwoSublocality = ref(''); 
    const destinationThree = ref(''); 
    const destinationThreeCity = ref(''); 
    const destinationThreeSublocality = ref(''); 
    const distance = ref(0);
    const duration = ref(0);
    const selectedFareType = ref('');
    const calculatedPrice = ref(0);
    const rideDateTime = ref('');
    const dateOption = ref('asap');
    const showFareTypes = ref(false);
    const showMap = ref(false);
    const showCustomerInfo = ref(false);
    const showRideOverview = ref(false);
    const mapComponent = ref(null);

    const luggage = ref({ large: 0, small: 0 });
    const passengerCount = ref(1);
    const hasBaggage = ref('no');

    const resetFareTypeComponent = () => {
      showFareTypes.value = false;
    };

    const resetMapComponent = () => {
      showMap.value = false;
    };

    const resetCustomerInfoComponent = () => {
      showCustomerInfo.value = false;
    };

    const resetRideOverviewComponent = () => {
      showRideOverview.value = false;
    };

    const updateDateFromRideDetails = ({ dateOption: newDateOption, reservationDate }) => {
      dateOption.value = newDateOption;
      rideDateTime.value = reservationDate;
    };

    const updateDistanceDuration = ({ distance: dist, duration: dur }) => {
      distance.value = dist;
      duration.value = dur;
      showFareTypes.value = true;
    };

    const handleCalculateRoute = ({
      pickup: start,
      pickupCity: pickCity,
      pickupSublocality: pickSublocality,
      destination: dest,
      destinationCity: destCity,
      destinationSublocality: destSubLocality,
      destinationTwo: two,
      destinationTwoCity: twoCity,
      destinationTwoSublocality: twoSubLocality,
      destinationThree: three,
      destinationThreeCity: threeCity,
      destinationThreeSublocality: threeSubLocality,
    }) => {
      pickup.value = start;
      pickupCity.value = pickCity;
      pickupSublocality.value = pickSublocality;
      destination.value = dest;
      destinationCity.value = destCity;
      destinationSublocality.value = destSubLocality;
      destinationTwo.value = two;
      destinationTwoCity.value = twoCity;
      destinationTwoSublocality.value = twoSubLocality;
      destinationThree.value = three;
      destinationThreeCity.value = threeCity;
      destinationThreeSublocality.value = threeSubLocality;

      showMap.value = true;
      showFareTypes.value = true;
    };

    const handleFareSelected = ({ type, price }) => {
      selectedFareType.value = type;
      calculatedPrice.value = price;
      showCustomerInfo.value = true;
    };

    const handleCustomerInfo = (info) => {
      customerInfo.value = info;
      showRideOverview.value = true;
    };

    const customerInfo = ref({
      name: '',
      phoneNumber: '',
      remarks: ''
    });

    const updateCustomerInfo = (newInfo) => {
      customerInfo.value = { ...newInfo };
    };

    const updateRideDetails = (options) => {
      hasBaggage.value = options.hasBaggage;
      luggage.value.large = options.largeLuggageCount;
      luggage.value.small = options.smallLuggageCount;
      passengerCount.value = options.passengerCount;
    };

    const loadMaps = () => {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.maps) {
          googleLoaded.value = true;
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBL90RdFndjyXMQM-dzSqs4C4hZw3m7ts0&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          googleLoaded.value = true;
          resolve();
        };
        script.onerror = (error) => reject(error);
        document.head.appendChild(script);
      });
    };


    const isReservation = computed(() => {
      return dateOption.value !== 'asap'; // True als het geen 'asap' is
    });

    const pickupTime = computed(() => {
      if (dateOption.value === 'asap') {
        // Huidige datum en tijd in dd-mm-yyyy hh:mm formaat
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Maanden beginnen bij 0
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}`;
      }
      // Gebruik de vooraf bepaalde datum en tijd
      return rideDateTime.value;
    });

    onMounted(() => loadMaps());

    return {
      googleLoaded,
      pickup,
      pickupCity,
      pickupSublocality,
      destination,
      destinationCity,
      destinationSublocality,
      destinationTwo,
      destinationTwoCity,
      destinationTwoSublocality,
      destinationThree,
      destinationThreeCity,
      destinationThreeSublocality,
      handleCalculateRoute,
      handleFareSelected,
      distance,
      duration,
      updateDistanceDuration,
      selectedFareType,
      rideDateTime,
      dateOption,
      pickupTime,
      customerInfo,
      updateCustomerInfo,
      handleCustomerInfo,
      calculatedPrice,
      showMap,
      showFareTypes,
      showCustomerInfo,
      showRideOverview,
      mapComponent,
      resetFareTypeComponent,
      resetMapComponent,
      resetCustomerInfoComponent,
      resetRideOverviewComponent,
      updateDateFromRideDetails,
      luggage,
      passengerCount,
      hasBaggage,
      updateRideDetails,
      isReservation,
    };
  },
};
</script>


<style scoped>
.tba-app-wrapper-desktop {
  position: relative;
  z-index: 1; /* Voor de inhoud */
}

.tba-app-wrapper-desktop::before {
  background-image: url('./assets/images/hero_photo_desktop_2.webp');
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.4); /* Donker overlay */
  z-index: -1;
}


.tba-components-container {
  display: flex;
  width: auto; /* Past de breedte aan op basis van de inhoud */
  flex-wrap: nowrap; /* Zorg ervoor dat items op één rij blijven */
  padding-top: 50px;
  padding-bottom: 50px;
}


.tba-left-section {
  flex: 1;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  opacity: 1;
  transition: opacity 1.5s ease;
  padding-left: 250px;
}

.tba-left-section.fade-out {
  opacity: 0;
  pointer-events: none;
}

.tba-right-section {
  flex: 1;
  display: flex;
  transition: all 1.5s ease; /* Voegt een vloeiende overgang toe voor breedte en padding */
}

.tba-right-section.expanded {
  position: absolute;
  left: 50%;                 /* Plaatst het element op 50% van de breedte van de pagina */
  transform: translateX(-50%); /* Verschuift het element naar links met de helft van zijn eigen breedte */
  padding-bottom: 50px;
}

.tba-form-wrapper {
  width: 100%; /* Past de form aan de breedte van de tba-right-section aan */
  max-width: 600px; /* Zorgt voor een vaste breedte voor de form */
}

.tba-form {
  width: 450px;
  height: 100%; /* Neemt de volledige hoogte van het ouder-element in */ 
}

/* Map en Fare Type Wrapper */
.tba-map-fare-container {
  width: 100%; 
  display: flex;
  flex-direction: column; /* Zorgt dat map en faretype onder elkaar staan */
  max-width: 600px; /* Zorgt voor een vaste breedte voor de form */
}

.tba-map-container {
  width: 500px;
  height: 450px; /* Pas de hoogte van de map aan */
  background-color: #e0e0e0; /* Optioneel: achtergrondkleur voor visuele weergave */
}

.tba-faretype-container {
  width: 500px;
  padding: 1.25rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optioneel: shadow voor styling */
  flex-grow: 1; /* Zorgt ervoor dat deze container flexibel groeit */
}

.tba-customer-info-wrapper,
.tba-ride-overview-wrapper {
  width: 100%;
  max-width: 600px;
}

.tba-customer-info-container {
  width: 450px; /* Stel een vaste breedte in */
  height: 100%; /* Neemt de volledige hoogte van het ouder-element in */
}

.tba-ride-overview-container {
  width: 400px; /* Stel een vaste breedte in */
  height: 100%; /* Neemt de volledige hoogte van het ouder-element in */
}


/*Vue JS CSS Components*/
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(10px); /* Startpositie: schuif iets naar rechts */
    opacity: 0; /* Start met onzichtbaarheid */
}

.slide-fade-enter-to,
.slide-fade-leave-from {
    transform: translateX(0); /* Eindpositie: geen verplaatsing */
    opacity: 1; /* Volledig zichtbaar */
}


.tba-intro-text {
  text-align: left;
  color: #ffffff;
}

.tba-headline {
  font-size: 4.5rem;
  font-weight: bold;
}

.tba-subtext {
  font-size: 3.25rem;
  margin-top: 0.5rem;
}

.tba-ride-overview-container h3 {
  margin-bottom: 16px; /* Ruimte onder de titel */
}

.tba-ride-overview-container p {
  margin-bottom: 8px; /* Meer ruimte onder elke paragraaf */
  line-height: 1.5;
}

.tba-ride-overview-container .flex {
  margin-bottom: 16px; /* Afstand tussen de secties */
}

button[type="button"] {
  margin-top: auto; /* Duwt de knop naar beneden */
}

.text-xs {
  margin-top: 16px;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--tba-primary-bg-color); /* Primaire achtergrondkleur */
  color: var(--tba-text-primary); /* Primaire tekstkleur */
  font-family: 'Arial', sans-serif;
  font-size: 1.2rem;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid var(--tba-border-light); /* Lichte randkleur */
  border-top: 5px solid var(--tba-accent); /* Accentkleur */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

/* Spinner Animatie */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>
