<template>
  <div class="tba-form tba-bg-primary-bg tba-p-3 tba-rounded-lg tba-shadow-md">

    <div class="tba-flex tba-items-center tba-justify-between tba-mb-5">
      <span class="tba-text-xl tba-text-text-primary tba-font-semibold">
        {{ translations[currentLanguage].RideDetailsComponent.title }}
      </span>
      <LanguageSelector
        :currentLanguage="currentLanguage"
        :languages="languages"
        @languageChanged="updateLanguage"
      />
    </div>

    <!-- Ophaal- en bestemmingsinvoer -->
    <AddressGroupComponent :googleLoaded="googleLoaded" @updateAddress="updateAddress" />

    <!-- Datum en tijd invoer -->
    <DateAndTimeGroupComponent @updateDate="updateDate" />

    <!-- Bagage en andere opties -->
    <AdditionalOptionsComponent @updateOptions="handleUpdateOptions" />

    <!-- Buttons for booking and calling -->
    <div class="button-group tba-flex tba-flex-col tba-gap-2">
      <!-- Bereken Rit -->
      <button
        type="button"
        id="calculateRide"
        class="black-button tba-bg-primary-button tba-text-text-on-primary tba-py-3 tba-font-bold tba-rounded-lg"
        :disabled="!rideDetails.pickup || !rideDetails.destination"
        @click="handleCalculateRide"
      >
        {{ translations[currentLanguage].RideDetailsComponent.calculateRideButton }}
      </button>

    <!-- Bel Ons Direct -->
    <a
      :href="`tel:${config.formSettings.companyPhone}`"
      id="belSpoed"
      class="black-button tba-bg-primary-button tba-text-text-on-primary tba-py-3 tba-font-bold tba-rounded-lg tba-hover:bg-red-500 tba-flex tba-items-center tba-justify-center"
    >
      {{ translations[currentLanguage].RideDetailsComponent.callUsButton }}
      <i class="fas fa-phone tba-ml-2"></i>
    </a>

    </div>


  </div>
</template>


<script>
import { ref, watch, inject } from 'vue';
import AddressGroupComponent from './AddressGroupComponent.vue';
import DateAndTimeGroupComponent from './DateAndTimeGroupComponent.vue';
import AdditionalOptionsComponent from './AdditionalOptionsComponent.vue';
import LanguageSelector from './LanguageSelector.vue';

export default {
  components: {
    AddressGroupComponent,
    DateAndTimeGroupComponent,
    AdditionalOptionsComponent,
    LanguageSelector,
  },
  props: ['googleLoaded', 'config'],
  emits: ['calculateRoute', 'updateDate', 'resetFareType', 'resetMap', 'resetCustomerInfo', 'resetRideOverview', 'updateOptions'], 
  setup(props, { emit }) {

    const currentLanguage = inject("currentLanguage"); // Huidige taal ophalen
    const translations = inject("translations"); // Vertalingen ophalen
    
    const rideDetails = ref({
      pickup: '',
      pickupCity: '',
      pickupSublocality: '', 
      destination: '',
      destinationCity: '',
      destinationSublocality: '',
      destinationTwo: '',
      destinationTwoCity: '',
      destinationTwoSublocality: '',
      destinationThree: '',
      destinationThreeCity: '',
      destinationThreeSublocality: '',
      dateOption: 'asap',
      reservationDate: null,
      largeLuggageCount: 0,
      smallLuggageCount: 0,
      passengerCount: 1,
      hasBaggage: 'no',
    });

    const updateAddress = ({
      pickup,
      pickupCity,
      pickupSublocality,
      destination,
      destinationCity,
      destinationSublocality,
      destinationTwo,
      destinationTwoCity,
      destinationTwoSublocality,
      destinationThree,
      destinationThreeCity,
      destinationThreeSublocality,
    }) => {
      rideDetails.value.pickup = pickup;
      rideDetails.value.pickupCity = pickupCity;
      rideDetails.value.pickupSublocality = pickupSublocality;
      rideDetails.value.destination = destination;
      rideDetails.value.destinationCity = destinationCity; 
      rideDetails.value.destinationSublocality = destinationSublocality; 
      rideDetails.value.destinationTwo = destinationTwo;
      rideDetails.value.destinationTwoCity = destinationTwoCity;
      rideDetails.value.destinationTwoSublocality = destinationTwoSublocality;
      rideDetails.value.destinationThree = destinationThree;
      rideDetails.value.destinationThreeCity = destinationThreeCity;
      rideDetails.value.destinationThreeSublocality = destinationThreeSublocality;
    };

    const updateDate = ({ dateOption, reservationDate }) => {
      rideDetails.value.dateOption = dateOption;
      rideDetails.value.reservationDate = reservationDate;
      emit('updateDate', { dateOption, reservationDate });
    };

    // Handler om `updateOptions` event te verwerken vanuit AdditionalOptionsComponent
    const handleUpdateOptions = (options) => {
      emit('updateOptions', options); // Stuur bijgewerkte opties naar App.vue
    };

    // Controleer of het ophaaladres binnen de toegestane gebieden ligt
    const handleCalculateRide = () => {
      const pickupCity = rideDetails.value.pickupCity;

      // Bepaal de laatste bestemming
      const destinationCity = 
        rideDetails.value.destinationThreeCity || 
        rideDetails.value.destinationTwoCity || 
        rideDetails.value.destinationCity;

      // Stap 1: Controleer of pickupCity in de lijst van werkgebieden staat
      const pickupArea = props.config.pickup_areas[pickupCity];
      if (!pickupArea) {
          alert(translations[currentLanguage.value].RideDetailsComponent.pickupAreaNotAllowed.replace('{city}', pickupCity));
        return; // Stop de berekening
      }

      // Stap 2: Controleer de minimale reserveringstijd
      const reservationDate = rideDetails.value.reservationDate || new Date();
      const currentTime = new Date();
      const timeDifferenceInMinutes = (reservationDate - currentTime) / (1000 * 60);

      if (timeDifferenceInMinutes < pickupArea.minimumReservationTime) {
          alert(
            translations[currentLanguage.value].RideDetailsComponent.minimumReservationTimeRequired
              .replace('{city}', pickupCity)
              .replace('{minutes}', pickupArea.minimumReservationTime)
          );
        return; // Stop de berekening
      }

      // Stap 3: Controleer of de bestemming beperkt is tot primaire gebieden
      if (pickupArea.onlyToPrimaryArea && destinationCity) {
        const destinationArea = props.config.pickup_areas[destinationCity];
        if (!destinationArea || !destinationArea.isPrimaryArea) {
            alert(
              translations[currentLanguage.value].RideDetailsComponent.destinationNotPrimary
                .replace('{pickupCity}', pickupCity)
                .replace('{destinationCity}', destinationCity)
            );
          return; // Stop de berekening
        }
      }

      // Als alle checks slagen, start de berekening
      emit('calculateRoute', rideDetails.value);
    };


    // Watcher om veranderingen in pickup en destination(s) te detecteren en reset te triggeren
    watch(
      () => ({
        pickup: rideDetails.value.pickup,
        pickupCity: rideDetails.value.pickupCity,
        pickupSublocality: rideDetails.value.pickupSublocality, // Nieuwe variabele
        destination: rideDetails.value.destination,
        destinationCity: rideDetails.value.destinationCity, // Nieuwe variabele
        destinationSublocality: rideDetails.value.destinationSublocality, // Nieuwe variabele
        destinationTwo: rideDetails.value.destinationTwo,
        destinationTwoCity: rideDetails.value.destinationTwoCity, // Nieuwe variabele
        destinationTwoSublocality: rideDetails.value.destinationTwoSublocality, // Nieuwe variabele
        destinationThree: rideDetails.value.destinationThree,
        destinationThreeCity: rideDetails.value.destinationThreeCity, // Nieuwe variabele
        destinationThreeSublocality: rideDetails.value.destinationThreeSublocality, // Nieuwe variabele
      }),
      (
        {
          pickup: newPickup,
          pickupCity: newPickupCity,
          pickupSublocality: newPickupSublocality,
          destination: newDestination,
          destinationCity: newDestinationCity,
          destinationSublocality: newDestinationSublocality,
          destinationTwo: newDestinationTwo,
          destinationTwoCity: newDestinationTwoCity,
          destinationTwoSublocality: newDestinationTwoSublocality,
          destinationThree: newDestinationThree,
          destinationThreeCity: newDestinationThreeCity,
          destinationThreeSublocality: newDestinationThreeSublocality,
        },
        {
          pickup: oldPickup,
          pickupCity: oldPickupCity,
          pickupSublocality: oldPickupSublocality,
          destination: oldDestination,
          destinationCity: oldDestinationCity,
          destinationSublocality: oldDestinationSublocality,
          destinationTwo: oldDestinationTwo,
          destinationTwoCity: oldDestinationTwoCity,
          destinationTwoSublocality: oldDestinationTwoSublocality,
          destinationThree: oldDestinationThree,
          destinationThreeCity: oldDestinationThreeCity,
          destinationThreeSublocality: oldDestinationThreeSublocality,
        }
      ) => {
        if (
          newPickup !== oldPickup ||
          newPickupCity !== oldPickupCity ||
          newPickupSublocality !== oldPickupSublocality ||
          newDestination !== oldDestination ||
          newDestinationCity !== oldDestinationCity ||
          newDestinationSublocality !== oldDestinationSublocality ||
          newDestinationTwo !== oldDestinationTwo ||
          newDestinationTwoCity !== oldDestinationTwoCity ||
          newDestinationTwoSublocality !== oldDestinationTwoSublocality ||
          newDestinationThree !== oldDestinationThree ||
          newDestinationThreeCity !== oldDestinationThreeCity ||
          newDestinationThreeSublocality !== oldDestinationThreeSublocality
        ) {
          emit('resetFareType');
          emit('resetMap');
          emit('resetCustomerInfo');
          emit('resetRideOverview');
        }
      }
    );


    // Watcher voor veranderingen in dateOption en reservationDate om direct te updaten
    watch(
      () => [rideDetails.value.dateOption, rideDetails.value.reservationDate],
      ([newDateOption, newReservationDate]) => {
        emit('updateDate', { dateOption: newDateOption, reservationDate: newReservationDate });
      }
    );

    return {
      rideDetails,
      updateAddress,
      updateDate,
      handleUpdateOptions,
      handleCalculateRide,
      currentLanguage,
      translations,
    };
  },
};
</script>

<style>
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
