<template>
  <div>
    <!-- Knop om taalkeuze te openen -->
    <button class="language-button" @click="toggleLanguageSelector">
      <span class="flag-icon" :class="'flag-icon-' + currentLanguage"></span>
      {{ languages[currentLanguage] }}
    </button>

    <!-- Taalkeuze-overlay -->
    <div v-if="showLanguageSelector" class="overlay" @click.self="toggleLanguageSelector">
      <div class="overlay-content">
        <h2>Choose Language</h2>
        <button
          v-for="(language, code) in languages"
          :key="code"
          @click="setLanguage(code)"
          class="language-option"
        >
          <span class="flag-icon" :class="'flag-icon-' + code"></span>
          {{ language }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';

export default {
  name: 'LanguageComponent',
  setup() {
    const currentLanguage = inject('currentLanguage'); // Injecteer de huidige taal
    const updateLanguage = inject('updateLanguage'); // Injecteer de updatefunctie

    const showLanguageSelector = ref(false); // Beheert zichtbaarheid van pop-up

    const languages = {
      gb: 'English',
      nl: 'Nederlands',
      fr: 'Français',
      de: 'Deutsch',
      es: 'Español',
      pt: 'Português',
      it: 'Italiano',
    };

    const toggleLanguageSelector = () => {
      showLanguageSelector.value = !showLanguageSelector.value;
    };

    const setLanguage = (languageCode) => {
      updateLanguage(languageCode); // Wijzig de taal via de geïnjecteerde functie
      showLanguageSelector.value = false; // Sluit pop-up
      console.log(`Language changed to: ${languageCode}`);
    };

    return {
      showLanguageSelector,
      currentLanguage,
      languages,
      toggleLanguageSelector,
      setLanguage,
    };
  },
};
</script>

<style scoped>
.language-button {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  color: #2d2d3f;
}

.language-button .flag-icon {
  margin-right: 5px;
}

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.overlay-content h2 {
  margin-bottom: 20px;
}

.overlay-content button {
  width: 200px;
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  background: #f0f0f0;
  border-radius: 5px;
}

.overlay-content button .flag-icon {
  margin-right: 10px;
}
</style>
