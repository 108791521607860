<template>
  <div ref="mapRef" class="tba-w-full tba-h-72"></div>
</template>

<script>
/* global google */
import { ref, watch, onMounted } from 'vue';

export default {
  props: ['pickup', 'destination', 'destinationTwo', 'destinationThree', 'googleLoaded'],
  emits: ['routeCalculated'],
  setup(props, { emit }) {
    const mapRef = ref(null);
    let mapInstance = null;
    let directionsService = null;
    let directionsRenderer = null;

    // Initialiseer de kaart
    const initializeMap = () => {
      if (!mapRef.value || mapInstance) return;

      mapInstance = new google.maps.Map(mapRef.value, {
        center: { lat: 52.3676, lng: 4.9041 },
        zoom: 12,
        disableDefaultUI: true,
      });

      directionsService = new google.maps.DirectionsService();
      directionsRenderer = new google.maps.DirectionsRenderer({
        map: mapInstance,
        polylineOptions: {
          strokeColor: '#000000',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        },
      });
    };


    // Bereken en geef de route weer, inclusief tussenstops
    const calculateAndDisplayRoute = () => {
      if (!props.pickup || !mapInstance) {
        console.log("Vereiste gegevens ontbreken voor routeberekening");
        return;
      }

      // Stel de waypoints en eindbestemming in op basis van ingevulde bestemmingen
      let waypoints = [];
      let finalDestination = props.destination;

      if (props.destinationTwo && !props.destinationThree) {
        waypoints.push({ location: props.destination, stopover: true });
        finalDestination = props.destinationTwo;
      } else if (props.destinationTwo && props.destinationThree) {
        waypoints.push({ location: props.destination, stopover: true });
        waypoints.push({ location: props.destinationTwo, stopover: true });
        finalDestination = props.destinationThree;
      }

      // Log de route-instellingen voor debuggen
      console.log("Pickup:", props.pickup);
      console.log("Waypoints:", waypoints);
      console.log("Final Destination:", finalDestination);

      const requestWithHighways = {
        origin: props.pickup,
        destination: finalDestination,
        waypoints: waypoints,
        travelMode: 'DRIVING',
        avoidHighways: false,
      };

      const requestWithoutHighways = {
        origin: props.pickup,
        destination: finalDestination,
        waypoints: waypoints,
        travelMode: 'DRIVING',
        avoidHighways: true,
      };

      const chooseShortestRoute = (responseWithHighways, responseWithoutHighways) => {
        const routeWithHighways = responseWithHighways.routes[0];
        const routeWithoutHighways = responseWithoutHighways.routes[0];
        const distanceWithHighways = routeWithHighways.legs.reduce((sum, leg) => sum + leg.distance.value, 0);
        const distanceWithoutHighways = routeWithoutHighways.legs.reduce((sum, leg) => sum + leg.distance.value, 0);

        if (distanceWithHighways <= distanceWithoutHighways) {
          displayRoute(responseWithHighways, routeWithHighways.legs);
        } else {
          displayRoute(responseWithoutHighways, routeWithoutHighways.legs);
        }
      };

      const displayRoute = (response, legs) => {
        directionsRenderer.setDirections(response);

        const locations = legs.map(leg => leg.start_location);
        locations.push(legs[legs.length - 1].end_location);


        const totalDistance = Math.ceil(legs.reduce((sum, leg) => sum + leg.distance.value, 0) / 1000);
        const totalDuration = Math.ceil(legs.reduce((sum, leg) => sum + leg.duration.value, 0) / 60);

        console.log(`Afstand: ${totalDistance} km, Tijd: ${totalDuration} min`);
        emit('routeCalculated', { distance: totalDistance, duration: totalDuration });
      };

      directionsService.route(requestWithHighways, (responseWithHighways, status) => {
        if (status === 'OK') {
          directionsService.route(requestWithoutHighways, (responseWithoutHighways, status) => {
            if (status === 'OK') {
              chooseShortestRoute(responseWithHighways, responseWithoutHighways);
            } else {
              displayRoute(responseWithHighways, responseWithHighways.routes[0].legs);
            }
          });
        } else {
          console.error('Routeberekening mislukt:', status);
        }
      });
    };

    onMounted(() => {
      if (props.googleLoaded) {
        initializeMap();
      }
    });

    watch(
      () => props.googleLoaded,
      (newVal) => {
        if (newVal) {
          initializeMap();
        }
      }
    );

    watch(
      () => [props.pickup, props.destination, props.destinationTwo, props.destinationThree],
      () => {
        if (props.pickup && props.destination) {
          calculateAndDisplayRoute();
        }
      }
    );

    return { mapRef };
  },
};
</script>
