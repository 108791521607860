<template>
  <span class="tba-section-title  tba-text-xl tba-text-text-secondary tba-font-semibold tba-mb-2 tba-block">
    {{ translations[currentLanguage].AddressGroupComponent.sectionTitle }}
  </span>
    
  <div class="tba-pl-4 tba-pt-4 tba-pb-3 tba-pr-1 tba-bg-input-bg tba-rounded-lg" style="border: 1px solid #e2e8f0;">
    <!-- Pickup Location -->
    <div class="tba-input-group tba-mb-3 tba-relative tba-flex tba-items-center" style="border-bottom: 1px solid #d1d5db; padding-bottom: 0.5rem;">
      <span class="tba-text-input-text-placeholder tba-font-bold tba-mr-3 tba-pb-2">A</span>
      <input
        type="text"
        id="pickupAddress"
        ref="pickupAddressInput"
        :placeholder="translations[currentLanguage].AddressGroupComponent.pickupPlaceholder"
        v-model="pickupAddress"
        required
        class="tba-w-full tba-bg-transparent tba-focus:outline-none tba-text-input-text tba-placeholder-input-text-placeholder tba-pb-2"
        style="outline: none; border: none;"
      />
      <div v-if="showPulseAnimation" class="pulse-animation tba-absolute tba-right-10 tba-top-1/2 tba-transform -tba-translate-y-1/2"></div>
      <button
        type="button"
        class="location-btn tba-absolute tba-right-10 tba-top-1/2 tba-pb-4 tba-transform -tba-translate-y-1/2"
        @click="useCurrentLocation"
      >
        <i class="fas fa-location-arrow"></i>
      </button>
      <span
        class="clear-btn tba-absolute tba-right-2 tba-top-1/2 tba-transform -tba-translate-y-2/3 tba-text-input-text-placeholder tba-cursor-pointer tba-pb-2"
        @click="clearPickup"
      >x</span>
    </div>

    <!-- Destination Location -->
    <div class="tba-input-group tba-mt-2 tba-relative tba-flex tba-items-center">
      <span class="tba-text-input-text-placeholder tba-font-bold tba-mr-3 tba-pb-2">B</span>
      <input
        type="text"
        id="destination"
        ref="destinationInput"
        :placeholder="translations[currentLanguage].AddressGroupComponent.destinationPlaceholder"
        v-model="destination"
        required
        class="tba-w-full tba-bg-transparent tba-focus:outline-none tba-text-input-text tba-placeholder-input-text-placeholder tba-pb-2"
        style="outline: none; border: none;"
      />
      <span
        class="clear-btn tba-absolute tba-right-2 tba-top-1/2 tba-transform -tba-translate-y-1/2 tba-text-input-text-placeholder tba-cursor-pointer tba-pb-2"
        @click="clearDestination"
      >x</span>
    </div>

    <!-- Destination 2 -->
    <div v-show="showDestinationTwo" class="tba-input-group tba-mt-2 tba-relative tba-flex tba-items-center" style="border-top: 1px solid #d1d5db; padding-top: 0.5rem;">
      <span class="tba-text-input-text-placeholder tba-font-bold tba-mr-3 tba-pb-2 tba-pt-1">C</span>
      <input
        type="text"
        id="destinationTwo"
        :placeholder="translations[currentLanguage].AddressGroupComponent.destinationTwoPlaceholder"
        v-model="destinationTwo"
        ref="destinationTwoInput"
        class="tba-w-full tba-bg-transparent tba-focus:outline-none tba-text-input-text tba-placeholder-input-text-placeholder tba-pb-2 tba-pt-1"
        style="outline: none; border: none;"
      />
      <span
        class="clear-btn tba-absolute tba-right-2 tba-top-1/2 tba-transform -tba-translate-y-1/2 tba-text-input-text-placeholder tba-cursor-pointer"
        @click="clearDestinationTwo"
      >x</span>
    </div>

    <!-- Destination 3 -->
    <div v-show="showDestinationThree" class="tba-input-group tba-mt-2 tba-relative tba-flex tba-items-center" style="border-top: 1px solid #d1d5db; padding-top: 0.5rem;">
      <span class="tba-text-input-text-placeholder tba-font-bold tba-mr-3 tba-pb-2 tba-pt-1">D</span>
      <input
        type="text"
        id="destinationThree"
        :placeholder="translations[currentLanguage].AddressGroupComponent.destinationThreePlaceholder"
        v-model="destinationThree"
        ref="destinationThreeInput"
        class="tba-w-full tba-bg-transparent tba-focus:outline-none tba-text-input-text tba-placeholder-input-text-placeholder tba-pb-2 tba-pt-1"
        style="outline: none; border: none;"
      />
      <span
        class="clear-btn tba-absolute tba-right-2 tba-top-1/2 tba-transform -tba-translate-y-1/2 tba-text-input-text-placeholder tba-cursor-pointer"
        @click="clearDestinationThree"
      >x</span>
    </div>
  </div>

  <!-- Add Destination Button -->
  <button v-if="!showDestinationThree" @click="addDestination" class="tba-ml-4 tba-text-accent tba-text-sm tba-font-semibold">
    {{ translations[currentLanguage].AddressGroupComponent.addDestinationButton }}
  </button>
</template>


<script>
import { ref, onMounted, watch, inject } from 'vue';


export default {
  props: ['googleLoaded'],
  emits: ['updateAddress'],
  setup(props, { emit }) {
    const currentLanguage = inject("currentLanguage"); // Huidige taal ophalen
    const translations = inject("translations"); // Vertalingen ophalen

    const pickupAddress = ref('');
    const pickupCity = ref('');
    const pickupSublocality = ref('');
    const destination = ref('');
    const destinationCity = ref('');
    const destinationSublocality = ref('');
    const destinationTwo = ref('');
    const destinationTwoCity = ref('');
    const destinationTwoSublocality = ref('');
    const destinationThree = ref('');
    const destinationThreeCity = ref('');
    const destinationThreeSublocality = ref('');
    const pickupAddressInput = ref(null);
    const destinationInput = ref(null);
    const destinationTwoInput = ref(null);
    const destinationThreeInput = ref(null);
    const showPulseAnimation = ref(true);
    const showDestinationTwo = ref(false);
    const showDestinationThree = ref(false);
    

    const setupAutocomplete = () => {
      if (pickupAddressInput.value && destinationInput.value && destinationTwoInput.value && destinationThreeInput.value) {
        const autocompleteOptions = {
          types: ['geocode', 'establishment'],
          componentRestrictions: { country: 'nl' },
        };

        // Autocomplete voor Pickup Location
        const pickupAutocomplete = new window.google.maps.places.Autocomplete(
          pickupAddressInput.value,
          autocompleteOptions
        );
        pickupAutocomplete.addListener('place_changed', () => {
          const place = pickupAutocomplete.getPlace();
          if (place && place.address_components) {
            // Haal het volledige adres op
            pickupAddress.value = place.formatted_address;

            // Haal de stad (locality) op uit address_components
            const cityComponent = place.address_components.find(component =>
              component.types.includes('locality')
            );
            pickupCity.value = cityComponent ? cityComponent.long_name : '';

            // Haal de sublocatie (sublocality) op
            const subLocalityComponent = place.address_components.find(component =>
              component.types.includes('sublocality') || component.types.includes('administrative_area_level_2')
            );
            pickupSublocality.value = subLocalityComponent ? subLocalityComponent.long_name : '';

            // Update de parent-component met beide gegevens
            emitUpdate();
          }
        });
        
      // Autocomplete voor Destination Location
      const destinationAutocomplete = new window.google.maps.places.Autocomplete(
        destinationInput.value,
        autocompleteOptions
      );
      destinationInput.value.addEventListener('input', () => {
        const countryRestriction = destinationInput.value.value.length > 4 ? ['nl', 'be', 'de'] : ['nl'];
        destinationAutocomplete.setComponentRestrictions({ country: countryRestriction });
      });
      destinationAutocomplete.addListener('place_changed', () => {
        const place = destinationAutocomplete.getPlace();
        if (place && place.address_components) {
          // Haal het volledige adres op
          destination.value = place.formatted_address;

          // Haal de stad (locality) op
          const cityComponent = place.address_components.find((component) =>
            component.types.includes('locality')
          );
          destinationCity.value = cityComponent ? cityComponent.long_name : '';

          // Haal de sublocatie (sublocality) op
          const subLocalityComponent = place.address_components.find((component) =>
            component.types.includes('sublocality') || component.types.includes('administrative_area_level_2')
          );
          destinationSublocality.value = subLocalityComponent ? subLocalityComponent.long_name : '';

          // Update de parent-component
          emitUpdate();
        }
      });

      // Autocomplete voor Bestemming 2
      const destinationTwoAutocomplete = new window.google.maps.places.Autocomplete(
        destinationTwoInput.value,
        autocompleteOptions
      );
      destinationTwoInput.value.addEventListener('input', () => {
        const countryRestriction = destinationTwoInput.value.value.length > 4 ? ['nl', 'be', 'de'] : ['nl'];
        destinationTwoAutocomplete.setComponentRestrictions({ country: countryRestriction });
      });
      destinationTwoAutocomplete.addListener('place_changed', () => {
        const place = destinationTwoAutocomplete.getPlace();
        if (place && place.address_components) {
          // Haal het volledige adres op
          destinationTwo.value = place.formatted_address;

          // Haal de stad (locality) op
          const cityComponent = place.address_components.find((component) =>
            component.types.includes('locality')
          );
          destinationTwoCity.value = cityComponent ? cityComponent.long_name : '';

          // Haal de sublocatie (sublocality) op
          const subLocalityComponent = place.address_components.find((component) =>
            component.types.includes('sublocality') || component.types.includes('administrative_area_level_2')
          );
          destinationTwoSublocality.value = subLocalityComponent ? subLocalityComponent.long_name : '';

          // Update de parent-component
          emitUpdate();
        }
      });

      // Autocomplete voor Bestemming 3
      const destinationThreeAutocomplete = new window.google.maps.places.Autocomplete(
        destinationThreeInput.value,
        autocompleteOptions
      );
      destinationThreeInput.value.addEventListener('input', () => {
        const countryRestriction = destinationThreeInput.value.value.length > 4 ? ['nl', 'be', 'de'] : ['nl'];
        destinationThreeAutocomplete.setComponentRestrictions({ country: countryRestriction });
      });
      destinationThreeAutocomplete.addListener('place_changed', () => {
        const place = destinationThreeAutocomplete.getPlace();
        if (place && place.address_components) {
          // Haal het volledige adres op
          destinationThree.value = place.formatted_address;

          // Haal de stad (locality) op
          const cityComponent = place.address_components.find((component) =>
            component.types.includes('locality')
          );
          destinationThreeCity.value = cityComponent ? cityComponent.long_name : '';

          // Haal de sublocatie (sublocality) op
          const subLocalityComponent = place.address_components.find((component) =>
            component.types.includes('sublocality') || component.types.includes('administrative_area_level_2')
          );
          destinationThreeSublocality.value = subLocalityComponent ? subLocalityComponent.long_name : '';

          // Update de parent-component
          emitUpdate();
        }
      });


      }
    };

    const addDestination = () => {
      if (!showDestinationTwo.value) {
        showDestinationTwo.value = true;
      } else if (!showDestinationThree.value) {
        showDestinationThree.value = true;
      }
    };

    const clearDestinationTwo = () => {
      destinationTwo.value = '';
      showDestinationTwo.value = false;
      emitUpdate();
    };

    const clearDestinationThree = () => {
      destinationThree.value = '';
      showDestinationThree.value = false;
      emitUpdate();
    };

    const useCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: pos }, (results, status) => {
              if (status === 'OK' && results[0]) {
                pickupAddress.value = results[0].formatted_address;
                emitUpdate();
                showPulseAnimation.value = false;
              } else {
                alert(translations[currentLanguage.value].geolocation.noResults);
              }
            });
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              alert(translations[currentLanguage.value].geolocation.permissionDenied);
            } else {
              alert(translations[currentLanguage.value].geolocation.cannotFetch);
            }
          }
        );
      } else {
        alert(translations[currentLanguage.value].geolocation.notSupported);
      }
    };


    const emitUpdate = () => {
      emit('updateAddress', {
        pickup: pickupAddress.value,
        pickupCity: pickupCity.value,
        pickupSublocality: pickupSublocality.value, 
        destination: destination.value,
        destinationCity: destinationCity.value, 
        destinationSublocality: destinationSublocality.value, 
        destinationTwo: destinationTwo.value,
        destinationTwoCity: destinationTwoCity?.value,
        destinationTwoSublocality: destinationTwoSublocality?.value,
        destinationThree: destinationThree.value,
        destinationThreeCity: destinationThreeCity?.value,
        destinationThreeSublocality: destinationThreeSublocality?.value,
      });
    };

    const clearPickup = () => {
      pickupAddress.value = '';
      pickupCity.value = '';
      pickupSublocality.value = '';
      emitUpdate();
    };

    const clearDestination = () => {
      destination.value = '';
      emitUpdate();
    };

    onMounted(() => {
      if (props.googleLoaded) setupAutocomplete();
    });

    watch(
      () => props.googleLoaded,
      (newVal) => {
        if (newVal) setupAutocomplete();
      }
    );

    return {
      pickupAddress,
      pickupCity,
      destination,
      destinationCity,
      destinationSublocality,
      destinationTwo,
      destinationTwoCity,
      destinationTwoSublocality,
      destinationThree,
      destinationThreeCity,
      destinationThreeSublocality,
      pickupAddressInput,
      destinationInput,
      destinationTwoInput,
      destinationThreeInput,
      showPulseAnimation,
      showDestinationTwo,
      showDestinationThree,
      addDestination,
      clearDestinationTwo,
      clearDestinationThree,
      emitUpdate,
      useCurrentLocation,
      clearPickup,
      clearDestination,
      currentLanguage,
      translations,
    };
  },
};
</script>

<style scoped>
.pulse-animation {
  width: 40px;
  height: 40px;
  border: 2px solid var(--tba-accent-color);
  border-radius: 50%;
  position: absolute;
  top: calc(40% - 24px);
  right: 27px;
  transform: translateY(-50%);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.location-btn i {
  font-size: 1rem;
  color: var(--tba-accent-color);
}

.location-btn:hover i {
  color: #19672c; /* Replace with a darker shade of your accent color if needed */
}

.clear-btn {
  font-size: 1.25rem;
  color: var(--tba-text-secondary-color);
  cursor: pointer;
}

.clear-btn:hover {
  color: #555; /* Replace this with a darker secondary color variable if defined */
}
</style>

