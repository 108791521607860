<template>
  <div class="tba-p-6 tba-bg-primary-bg tba-rounded-lg tba-shadow-md tba-mx-auto tba-customer-info-container" data-customer-info>
    <h3 class="tba-text-xl tba-font-semibold tba-mb-6 tba-text-text-primary">
      {{ translations[currentLanguage].CustomerInfoComponent.sectionTitle }}
    </h3>
    
    <form @submit.prevent="submitCustomerInfo">
      <!-- Naam Input -->
      <div class="tba-mb-6 tba-bg-secondary-bg tba-p-4 tba-rounded-md tba-shadow">
        <label class="tba-block tba-text-sm tba-font-semibold tba-text-text-primary tba-mb-2">
          {{ translations[currentLanguage].CustomerInfoComponent.nameLabel }}
        </label>
        <input
          type="text"
          v-model="name"
          class="input-field"
          :placeholder="translations[currentLanguage].CustomerInfoComponent.namePlaceholder"
          required
          minlength="1"
          maxlength="25"
        />
        <small class="tba-block tba-text-xs tba-text-text-secondary tba-mt-1 tba-italic">
          {{ translations[currentLanguage].CustomerInfoComponent.nameInfo }}
        </small>
      </div>

      <!-- WhatsApp Nummer Input -->
      <div class="tba-mb-6 tba-bg-secondary-bg tba-p-4 tba-rounded-lg tba-shadow-md">
        <label class="tba-block tba-text-sm tba-font-semibold tba-text-text-primary tba-mb-2">
          {{ translations[currentLanguage].CustomerInfoComponent.phoneNumberLabel }}
        </label>
        <input
          type="tel"
          v-model="phoneNumber"
          class="input-field"
          :placeholder="translations[currentLanguage].CustomerInfoComponent.phoneNumberPlaceholder"
          required
        />
        <small class="tba-block tba-text-xs tba-text-text-secondary tba-mt-1 tba-italic">
          {{ translations[currentLanguage].CustomerInfoComponent.phoneNumberInfo }}
        </small>
        <p v-if="errorMessage" class="tba-text-danger tba-text-sm tba-mt-2">
          {{ translations[currentLanguage].CustomerInfoComponent.phoneNumberInvalidError }}
        </p>
      </div>

      <!-- Speciale Instructies -->
      <div class="tba-mb-6 tba-bg-secondary-bg tba-p-4 tba-rounded-lg tba-shadow-md">
        <label class="tba-block tba-text-sm tba-font-semibold tba-text-text-primary tba-mb-2">
          {{ translations[currentLanguage].CustomerInfoComponent.remarksLabel }}
        </label>
        <textarea
          v-model="remarks"
          class="input-field tba-resize-none"
          :placeholder="translations[currentLanguage].CustomerInfoComponent.remarksPlaceholder"
          maxlength="150"
          rows="3"
        ></textarea>
      </div>

      <!-- Verzendknop -->
      <button
        type="submit"
        class="tba-w-full tba-py-3 tba-bg-secondary-button tba-text-white tba-font-semibold tba-rounded-lg tba-hover:bg-secondary-button-hover tba-transition tba-duration-200"
      >
        {{ translations[currentLanguage].CustomerInfoComponent.submitButton }}
      </button>
    </form>
  </div>
</template>

<script>
import { inject } from 'vue';


export default {
  emits: ['customerInfoSubmitted', 'customerInfoChanged'],
  data() {
    return {
      name: '',
      phoneNumber: '',
      remarks: '',
      errorMessage: ''
    };
  },
  setup() {
    const currentLanguage = inject("currentLanguage"); // Huidige taal ophalen
    const translations = inject("translations"); // Vertalingen ophalen

    return {
      currentLanguage,
      translations
    };
  },
  watch: {
    // Watch de velden en stuur wijzigingen door
    name() {
      this.emitCustomerInfoChanged();
    },
    phoneNumber() {
      this.emitCustomerInfoChanged();
    },
    remarks() {
      this.emitCustomerInfoChanged();
    },
  },
  methods: {
    // Methode om het bijgewerkte klantinformatie event uit te zenden
    emitCustomerInfoChanged() {
      this.$emit('customerInfoChanged', {
        name: this.name,
        phoneNumber: this.phoneNumber,
        remarks: this.remarks,
      });
    },
    submitCustomerInfo() {
      this.verifyPhoneNumber(this.phoneNumber, (error) => {
        if (error) {
          this.errorMessage = error.message;
        } else {
          this.errorMessage = '';
          this.$emit('customerInfoSubmitted', {
            name: this.name,
            phoneNumber: this.phoneNumber,
            remarks: this.remarks,
          });
        }
      });
    },
    verifyPhoneNumber(number, callback) {
      const lang = this.currentLanguage.value; // Huidige taal ophalen
      const phoneNumberErrors = this.translations[lang]?.customerInfo?.phoneNumberErrors;

      fetch('https://wat-kost-een-taxi.com/api/verify-phone', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ nummer: number })
      })
        .then(response => response.json())
        .then(data => {
          if (data.isValid && data.isMobile) {
            callback(null);
          } else {
            const errorMessage = phoneNumberErrors?.invalidNumber;
            callback(new Error(errorMessage));
          }
        })
        .catch(() => {
          const errorMessage = phoneNumberErrors?.verificationError;
          callback(new Error(errorMessage));
        });
    },
  }
};

</script>

<style scoped>
/* General styling for input and textarea fields */
.input-field {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--tba-border-color); /* Using theme border color */
  border-radius: 0.375rem;
  outline: none;
  font-size: 0.875rem;
  color: var(--tba-input-text-color); /* Using theme input text color */
  background-color: var(--tba-input-bg-color); /* Using theme input background */
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.input-field:focus {
  border-color: var(--tba-input-focus-border-color); /* Focus border color from theme */
  box-shadow: 0 0 0 3px rgba(var(--tba-accent-color-rgb), 0.2); /* Transparent shadow */
}

.input-field::placeholder {
  color: var(--tba-text-secondary-color); /* Placeholder color from theme */
}

/* Styling for the submit button */
button[type="submit"] {
  background-color: var(--tba-accent-color); /* Primary accent color */
  color: var(--tba-text-on-primary-color); /* Text color for buttons */
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem;
  border-radius: 0.375rem;
}

button[type="submit"]:hover {
  background-color: var(--tba-secondary-button-color-hover); /* Hover background color from theme */
}



</style>
