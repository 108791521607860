<template>
  <div v-if="isDataReady" id="tba-reviews-section" class="tba-pt-4 tba-text-center">
    <div>
      <!-- Header -->
      <div
        :class="[
          'google-reviews-header tba-flex tba-items-center tba-mb-8',
          isMobile ? 'tba-justify-center' : 'tba-justify-start tba-w-full'
        ]"
      >
        <img
          v-if="isMobile"
          src="@/assets/images/google-reviews.webp"
          alt="Google Reviews"
          class="google-logo tba-mr-4"
        />
        <div :class="[isMobile ? 'tba-text-left' : 'tba-text-left tba-text-white']">
          <h2 :class="[isMobile ? 'tba-text-2xl' : 'tba-text-5xl', 'tba-font-bold']">
            {{ translations[currentLanguage].ReviewsComponent.headerTitle }}
          </h2>
          <p :class="[isMobile ? 'tba-text-gray-600' : 'tba-text-4xl tba-text-gray-200']">
            {{ translations[currentLanguage].ReviewsComponent.headerDescription
              .replace('{rating}', rating)
              .replace('{totalReviews}', totalReviews) }}
          </p>
        </div>
      </div>
    </div>

    <!-- Reviews -->
    <div ref="scrollContainer" class="review-card-container tba-flex tba-overflow-x-auto">
      <div
        v-for="(review, index) in reviews"
        :key="index"
        class="review-card tba-p-6 tba-rounded-lg tba-shadow-lg tba-mx-2"
      >
        <p class="review-text tba-mb-4 tba-text-left">{{ review.text }}</p>
        <div class="review-footer tba-text-sm tba-text-left tba-mt-2">
          <span class="review-author tba-font-bold">
            ~ {{ formatAuthorName(review.authorName) }}
          </span>
          <div class="tba-flex tba-items-center tba-text-gray-400 tba-mt-1">
            <span class="review-stars tba-text-yellow-400 tba-text-lg">
              {{ '★'.repeat(Math.round(review.rating)) }}
            </span>
            <span class="review-time tba-ml-4 tba-italic">{{ review.time }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted } from "vue";

export default {
  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const translations = inject("translations");
    const currentLanguage = inject("currentLanguage");
    const rating = ref(0);
    const reviews = ref([]);
    const totalReviews = ref(0);
    const scrollContainer = ref(null);
    const isDataReady = ref(false); // Toevoegen van een loading-status

    const fetchReviews = async () => {
      try {
        const response = await fetch("https://wat-kost-een-taxi.com/api/public/reviews");
        if (!response.ok) {
          throw new Error("Fout bij ophalen van reviews");
        }
        const data = await response.json();

        reviews.value = data.reviews || [];
        totalReviews.value = data.totalReviews || 0;
        rating.value = data.totalRating || 0;

        isDataReady.value = true; // Markeer dat de data is geladen
      } catch (error) {
        console.error("Fout bij ophalen van reviews:", error);
      }
    };


    const startAutoScroll = () => {
      const cardWidth = 315; // Breedte van elke reviewkaart inclusief marges

      const autoScrollInterval = setInterval(() => {
        if (
          scrollContainer.value &&
          scrollContainer.value.scrollLeft + scrollContainer.value.clientWidth >=
            scrollContainer.value.scrollWidth
        ) {
          scrollContainer.value.scrollLeft = 0;
        } else {
          scrollContainer.value.scrollBy({ left: cardWidth, behavior: "smooth" });
        }
      }, 6000);

      return () => {
        clearInterval(autoScrollInterval); // Opruimen van interval
      };
    };

    onMounted(() => {
      console.log("Component gemount...");
      fetchReviews(); // Reviews ophalen
      startAutoScroll(); // Scroll automatisch
    });

    const formatAuthorName = (name) => {
      if (!name || typeof name !== "string") {
        return "Onbekende auteur";
      }

      const [firstName, lastName] = name.split(" ");
      const formattedName = lastName ? `${firstName} ${lastName.charAt(0)}.` : firstName;
      return formattedName;
    };

    return {
      translations,
      currentLanguage,
      rating,
      reviews,
      totalReviews,
      scrollContainer,
      fetchReviews,
      startAutoScroll,
      formatAuthorName,
      isDataReady,
    };
  },
};
</script>




<style scoped>
.google-logo {
  width: 150px; /* of gebruik Tailwind-klasse w-36 */
  height: auto;
}

.review-card-container {
  padding-bottom: 1rem; /* Indien nodig, anders vervangen door Tailwind pb-4 */
}

.review-card {
  width: 300px;
  height: 200px;
  background-color: #2d2d3f;
  color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
}

.review-text {
  font-size: 1rem;
  line-height: 1.5;
  color: #f3f3f3;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Behoud voor tekstklemmen */
}

/* Verberg de scrollbar in Firefox */
.review-card-container {
  scrollbar-width: thin; /* Maakt de scrollbar dunner in Firefox */
  scrollbar-color: #888 #2d2d3f; /* Scrollbar kleur in Firefox */
}

/* Chrome, Safari en Edge */
.review-card-container::-webkit-scrollbar {
  width: 6px; /* Breedte van de scrollbar */
  height: 6px; /* Hoogte van de scrollbar bij horizontaal scrollen */
}

.review-card-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Kleur van de scrollbar */
  border-radius: 10px; /* Maak de scrollbar afgerond */
}

.review-card-container::-webkit-scrollbar-track {
  background: #2d2d3f; /* Achtergrondkleur van de scrollbar-track */
}

</style>

