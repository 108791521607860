<template>
  <div>
    <div class="tba-mb-8 tba-mt-8">
      <span class="tba-section-title tba-text-text-secondary tba-text-xl tba-font-semibold tba-mb-2 tba-block">
        {{ translations[currentLanguage].DateAndTimeGroupComponent.sectionTitle }}
      </span>
      
      <div class="tba-flex tba-justify-center tba-max-w-md tba-mx-auto">
        <!-- Slider-stijl -->
        <nav
          v-if="buttonType === 'slider'"
          class="tba-relative tba-flex tba-items-center tba-pt-2 tba-pb-2 tba-bg-switch-button-grey tba-rounded-xl tba-w-full"
        >
          <!-- Actieve achtergrond -->
          <div
            class="tba-absolute tba-inset-y-1 tba-left-1 tba-w-[49%] tba-bg-hover tba-rounded-md tba-shadow-md tba-transition-transform tba-duration-300"
            :class="{ 'tba-translate-x-full': dateOption === 'reserve' }"
          ></div>

          <!-- ASAP Optie -->
          <input type="radio" id="asap" name="dateOption" value="asap" class="tba-hidden" v-model="dateOption" />
          <label
            for="asap"
            class="tba-relative tba-flex-1 tba-h-8 tba-flex tba-items-center tba-justify-center tba-font-medium tba-z-10 tba-cursor-pointer tba-transition-colors tba-duration-300"
            :class="{ 'tba-text-text-on-primary tba-font-semibold': dateOption === 'asap', 'tba-text-text-on-primary': dateOption !== 'asap' }"
          >
            {{ translations[currentLanguage].DateAndTimeGroupComponent.asapOption }}
          </label>

          <!-- Reserve Optie -->
          <input type="radio" id="reserve" name="dateOption" value="reserve" class="tba-hidden" v-model="dateOption" />
          <label
            for="reserve"
            class="tba-relative tba-flex-1 tba-h-8 tba-flex tba-items-center tba-justify-center tba-font-medium tba-z-10 tba-cursor-pointer tba-transition-colors tba-duration-300"
            :class="{ 'tba-text-text-on-primary tba-font-semibold': dateOption === 'reserve', 'tba-text-text-on-primary': dateOption !== 'reserve' }"
          >
            {{ translations[currentLanguage].DateAndTimeGroupComponent.reserveOption }}
          </label>
        </nav>

        <!-- Simpele knoppen-stijl -->
        <nav
          v-else
          class="tba-flex tba-gap-1 tba-w-full tba-rounded-xl"
        >
          <!-- ASAP Optie -->
          <button
            type="button"
            class="tba-relative tba-flex-1 tba-px-4 tba-py-2 tba-border tba-rounded-md tba-text-sm tba-font-medium tba-transition-colors tba-duration-300"
            :class="{ 
              'tba-border-text-primary tba-border tba-text-text-secondary': dateOption === 'asap', 
              'tba-border-gray-300 tba-text-gray-700': dateOption !== 'asap' 
            }"
            @click="dateOption = 'asap'"
          >
            {{ translations[currentLanguage].DateAndTimeGroupComponent.asapOption }}
            <!-- Puntje in de hoek -->
            <span
              v-if="dateOption === 'asap'"
              class="tba-absolute tba-top-1 tba-right-1 tba-w-2 tba-h-2 tba-bg-text-secondary tba-rounded-full"
            ></span>
          </button>

          <!-- Reserve Optie -->
          <button
            type="button"
            class="tba-relative tba-flex-1 tba-px-4 tba-py-2 tba-border tba-rounded-md tba-text-sm tba-font-medium tba-transition-colors tba-duration-300"
            :class="{ 
              'tba-border-text-primary tba-border tba-text-text-secondary': dateOption === 'reserve', 
              'tba-border-gray-300 tba-text-gray-700': dateOption !== 'reserve' 
            }"
            @click="dateOption = 'reserve'"
          >
            {{ translations[currentLanguage].DateAndTimeGroupComponent.reserveOption }}
            <!-- Puntje in de hoek -->
            <span
              v-if="dateOption === 'reserve'"
              class="tba-absolute tba-top-1 tba-right-1 tba-w-2 tba-h-2 tba-bg-text-secondary  tba-rounded-full"
            ></span>
          </button>
        </nav>


      </div>


      <transition name="slide-fade">
        <div v-if="dateOption === 'reserve'" class="tba-mt-4 tba-overflow-hidden">
          <div class="tba-flex tba-w-full tba-space-x-4">
            <div class="tba-w-1/2">
              <label class="tba-block tba-font-semibold tba-text-text-primary tba-mb-1">
                {{ translations[currentLanguage].DateAndTimeGroupComponent.dateLabel }}
              </label>
              <input type="date" :min="today" v-model="reservationDate" class="tba-input-field" />
            </div>
            <div class="tba-w-1/2">
              <label class="tba-block tba-font-semibold tba-text-text-primary tba-mb-1">
                {{ translations[currentLanguage].DateAndTimeGroupComponent.timeLabel }}
              </label>
              <input type="time" :min="minTime" v-model="reservationTime" class="tba-input-field" />
            </div>
          </div>

          <p v-if="warningMessage" class="tba-text-danger tba-mt-2">
            {{ translations[currentLanguage].DateAndTimeGroupComponent.warningMessage }}
          </p>

          <hr class="tba-my-4" />

          <div class="tba-quick-options tba-flex tba-space-x-2 tba-mt-4">
            <button class="tba-quick-option-btn" @click="setTimeInMinutes(10)">
              {{ translations[currentLanguage].DateAndTimeGroupComponent.quickOptions_in10Minutes }}
            </button>
            <button class="tba-quick-option-btn" @click="setTimeInMinutes(20)">
              {{ translations[currentLanguage].DateAndTimeGroupComponent.quickOptions_in20Minutes }}
            </button>
            <button class="tba-quick-option-btn" @click="setTimeInMinutes(30)">
              {{ translations[currentLanguage].DateAndTimeGroupComponent.quickOptions_in30Minutes }}
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>



<script>
import { ref, computed, watch, inject } from 'vue';

export default {
  emits: ['updateDate'],
  props: {
    buttonType: {
      type: String,
      required: true, // Zorg ervoor dat de prop verplicht is
    },
  },
  setup(_, { emit }) {

    const currentLanguage = inject("currentLanguage"); // Huidige taal ophalen
    const translations = inject("translations"); // Vertalingen ophalen



    const dateOption = ref('asap');
    const reservationDate = ref(null);
    const reservationTime = ref(null);
    const warningMessage = ref('');

    // Huidige datum in yyyy-mm-dd formaat voor het beperken van eerdere data en standaardwaarde
    const today = new Date().toISOString().split("T")[0];

    // Minimale tijd voor vandaag
    const minTime = computed(() => {
      const now = new Date();
      return reservationDate.value === today
        ? `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`
        : "00:00";
    });

    // Formatteer de datum in dd-mm-yyyy formaat
    const formattedDate = computed(() => {
      if (!reservationDate.value) return null;
      const [year, month, day] = reservationDate.value.split("-");
      return `${day}-${month}-${year}`;
    });

    // Gecombineerde datum en tijd in dd-mm-yyyy hh:mm formaat
    const formattedDateTime = computed(() => {
      return formattedDate.value && reservationTime.value
        ? `${formattedDate.value} ${reservationTime.value}`
        : null;
    });

    const emitUpdate = () => {
      console.log("Geformatteerde datum en tijd (formattedDateTime):", formattedDateTime.value);
      emit('updateDate', {
        dateOption: dateOption.value,
        reservationDate: formattedDateTime.value,
      });
    };

    const setTimeInMinutes = (minutes = 15) => {
      const now = new Date();
      now.setMinutes(now.getMinutes() + minutes); 
      reservationTime.value = now.toTimeString().slice(0, 5); // 24-uurs formaat (HH:MM)
      emitUpdate();
    };

    // Watch `dateOption` om updates te versturen wanneer "Boek voor later" is geselecteerd
    watch(dateOption, (newVal) => {
      if (newVal === 'reserve') {
        reservationDate.value = today; // Stel standaarddatum in op vandaag
        setTimeInMinutes(); // Stel standaard tijd in op 15 minuten vanaf nu
      } else {
        reservationDate.value = null;
        reservationTime.value = null;
        emitUpdate(); // Emit zonder datum en tijd voor "asap"
      }
    });

    watch(reservationDate, (newDate) => {
      if (newDate && newDate < today) {
        reservationDate.value = today;
        warningMessage.value = 'Kies een datum die vandaag of later is.';
        
        // Verwijder het bericht na 3 seconden
        setTimeout(() => {
          warningMessage.value = '';
        }, 3000);
      }
    });

    watch(reservationTime, (newTime) => {
      if (reservationDate.value === today) {
        const now = new Date();
        const currentTime = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;

        if (newTime && newTime < currentTime) {
          reservationTime.value = currentTime;
          warningMessage.value = 'Kies een tijd die later is dan de huidige tijd.';
          
          // Verwijder het bericht na 3 seconden
          setTimeout(() => {
            warningMessage.value = '';
          }, 3000);
        }
      }
    });

    // Watchers voor datum en tijd om bij elke aanpassing te emiten
    watch([reservationDate, reservationTime], () => {
      console.log("Datum gewijzigd naar:", reservationDate.value);
      console.log("Tijd gewijzigd naar:", reservationTime.value);
      emitUpdate();
    });

    return {
      dateOption,
      reservationDate,
      reservationTime,
      today,
      minTime,
      setTimeInMinutes,
      formattedDateTime,
      currentLanguage,
      translations,
      
    };
  }
};
</script>

<style scoped>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
  overflow: hidden;
}
.slide-fade-enter-from, .slide-fade-leave-to {
  max-height: 0;
  opacity: 0;
}
.slide-fade-enter-to, .slide-fade-leave-from {
  max-height: 500px;
  opacity: 1;
}

.tba-input-field {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--tba-border-color);
  border-radius: 0.375rem;
  outline: none;
  font-size: 1rem;
  color: var(--tba-input-text-color);
  background-color: var(--tba-input-bg-color);
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.tba-input-field:focus {
  border-color: var(--tba-input-focus-border-color);
  box-shadow: 0 0 0 3px rgba(var(--tba-accent-color-rgb), 0.2);
}

.tba-quick-option-btn {
  padding: 0.5rem 1rem;
  border: 1px solid var(--tba-border-color);
  color: var(--tba-text-primary-color);
  border-radius: 0.375rem;
  background-color: var(--tba-primary-bg-color);
  transition: border-color 0.2s ease, color 0.2s ease;
}

.tba-quick-option-btn:hover {
  border-color: var(--tba-accent-color);
  color: var(--tba-accent-color);
}

hr {
  border-top: 1px solid var(--tba-border-color);
  width: 50%;
  margin: 1rem auto;
}

input[type="date"], input[type="time"] {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>