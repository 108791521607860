<template>
  <component :is="type === 'pc' ? 'AppDesktop' : 'AppMobile'" 
              :isMobile="type === 'mobile'" 
              :config="config" />
</template>

<script>
import { ref, provide } from "vue";
import AppMobile from "./AppMobile.vue";
import AppDesktop from "./AppDesktop.vue";
import localConfig from "./localConfig.js"; // Importeer de lokale configuratie
import translationsData from "./locales/translations.json"; // Importeer vertalingen

export default {
  components: {
    AppMobile,
    AppDesktop,
  },
  setup() {
    // Functie om apparaattaal te detecteren
    const getDeviceLanguage = () => {
      const deviceLanguage = navigator.language || navigator.languages[0];
      return deviceLanguage ? deviceLanguage.split('-')[0] : null; // Alleen taalcode
    };

    // Detecteer de taal (prioriteiten: opgeslagen voorkeur, device, fallback)
    const savedLanguage = localStorage.getItem('preferredLanguage'); // Controleer of er een opgeslagen voorkeur is
    const detectedLanguage = savedLanguage || getDeviceLanguage() || "nl"; // Fallback naar Nederlands

    // Reactieve variabelen
    const currentLanguage = ref(detectedLanguage); // Stel gedetecteerde taal in
    const translations = translationsData;

    // Functie om handmatig de taal te wijzigen
    const updateLanguage = (lang) => {
      currentLanguage.value = lang;
      localStorage.setItem('preferredLanguage', lang); // Sla voorkeur op
    };

    // Deel met child-componenten
    provide("currentLanguage", currentLanguage);
    provide("translations", translations);
    provide("updateLanguage", updateLanguage);

    return {
      currentLanguage,
      updateLanguage,
    };
  },
  data() {
    return {
      type: window.innerWidth >= 769 ? "pc" : "mobile",
      config: null, // Config wordt hier geladen
      useLocalConfig: false, // Zet deze op true om de lokale configuratie te gebruiken
    };
  },
  mounted() {
    this.adjustParentContainer();
    window.addEventListener("resize", this.checkDeviceType);
    this.fetchConfig(); // Haal de config data op bij het laden van de app
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkDeviceType);
  },
  methods: {
    async fetchConfig() {
      if (this.useLocalConfig) {
        // Gebruik lokale configuratie
        console.log("Lokale configuratie geladen:", localConfig);
        this.config = localConfig;
      } else {
        // Gebruik API-configuratie
        try {
          const response = await fetch("https://wat-kost-een-taxi.com/api/public/config");
          if (!response.ok) {
            throw new Error(`HTTP-fout! status: ${response.status}`);
          }
          this.config = await response.json();
          console.log("Config geladen:", this.config);
        } catch (error) {
          console.error("Fout bij het laden van de config:", error.message);
        }
      }
    },
    checkDeviceType() {
      this.type = window.innerWidth >= 769 ? "pc" : "mobile";
    },
    adjustParentContainer() {
      const parentContainer = this.$el.parentElement;
      if (parentContainer) {
        parentContainer.style.width = "100vw";
        parentContainer.style.maxWidth = "none";
        parentContainer.style.margin = "0 auto";
      }
    },
  },
};
</script>

<style scoped>
/* Voeg hier je styles toe als nodig */
</style>
