f<template>
  <div class="tba-scale-wrapper tba-min-h-screen tba-bg-gray-100">
    
    <RideDetailsComponent
      :googleLoaded="googleLoaded"
      :config="config"
      @calculateRoute="handleCalculateRoute"
      @updateAddress="handleAddressUpdate"
      @resetFareType="resetFareTypeComponent"
      @resetMap="resetMapComponent"                  
      @resetCustomerInfo="resetCustomerInfoComponent" 
      @resetRideOverview="resetRideOverviewComponent"
      @updateDate="updateDateFromRideDetails"
      @updateOptions="updateRideDetails"
    />

    <div v-show="showMap">
      <MapComponent
        ref="mapComponent"
        :googleLoaded="googleLoaded"
        :pickup="pickup"
        :destination="destination"
        :destinationTwo="destinationTwo"
        :destinationThree="destinationThree"
        @routeCalculated="updateDistanceDuration"
        data-map
      />
    </div>

    <FareTypeComponent
      v-if="showMap && showFareTypes"
      :distance="distance"
      :duration="duration"
      :pickupCity="pickupCity"
      :destinationCity="destinationCity"
      :destinationTwoCity="destinationTwoCity"
      :destinationThreeCity="destinationThreeCity"
      :config="config"
      @fareSelected="handleFareSelected"
    />

    <CustomerInfoComponent
      v-if="showCustomerInfo"
      @customerInfoChanged="updateCustomerInfo"
      @customerInfoSubmitted="handleCustomerInfo"
    />

    <RideOverviewComponent
      v-if="showRideOverview"
        :pickupAddress="pickup"
        :pickupCity="pickupCity"
        :pickupSublocality="pickupSublocality"
        :destinationAddress="destination"
        :destinationCity="destinationCity"
        :destinationSublocality="destinationSublocality"
        :destinationTwo="destinationTwo"
        :destinationTwoCity="destinationTwoCity"
        :destinationTwoSublocality="destinationTwoSublocality"
        :destinationThree="destinationThree"
        :destinationThreeCity="destinationThreeCity"
        :destinationThreeSublocality="destinationThreeSublocality"
        :distance="distance"
        :duration="duration"
        :fareType="selectedFareType"
        :dateAndTime="pickupTime"
        :price="calculatedPrice"
        :customerInfo="customerInfo"
        :luggage="luggage"
        :passengerCount="passengerCount"
        :hasBaggage="hasBaggage"
        :isReservation="isReservation"
    />

    <ReviewsComponent v-if="config.formSettings.showReviews" :isMobile="isMobile" />
  </div>
</template>

<script>
import { ref, computed, onMounted, nextTick } from 'vue';
import RideDetailsComponent from './components/RideDetailsComponent.vue';
import MapComponent from './components/MapComponent.vue';
import FareTypeComponent from './components/FareTypeComponent.vue';
import CustomerInfoComponent from './components/CustomerInfoComponent.vue';
import RideOverviewComponent from './components/RideOverviewComponent.vue';
import ReviewsComponent from './components/ReviewsComponent.vue';

export default {
  components: {
    RideDetailsComponent,
    MapComponent,
    FareTypeComponent,
    CustomerInfoComponent,
    RideOverviewComponent,
    ReviewsComponent,
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const googleLoaded = ref(false);
    const pickup = ref('');
    const pickupCity = ref('');
    const pickupSublocality = ref('');
    const destination = ref('');
    const destinationCity = ref('');
    const destinationSublocality = ref('');
    const destinationTwo = ref(''); 
    const destinationTwoCity = ref(''); 
    const destinationTwoSublocality = ref(''); 
    const destinationThree = ref(''); 
    const destinationThreeCity = ref(''); 
    const destinationThreeSublocality = ref(''); 
    const distance = ref(0);
    const duration = ref(0);
    const selectedFareType = ref('');
    const calculatedPrice = ref(0);
    const rideDateTime = ref('');
    const dateOption = ref('asap');
    const showFareTypes = ref(false);
    const showMap = ref(false);
    const showCustomerInfo = ref(false);
    const showRideOverview = ref(false);
    const mapComponent = ref(null);

    const luggage = ref({ large: 0, small: 0 });
    const passengerCount = ref(1);
    const hasBaggage = ref('no');

    // Reset function for fare type without recalculating the route
    const resetFareTypeComponent = () => {
      showFareTypes.value = false;
    };

    const resetMapComponent = () => {
      showMap.value = false;
    };

    const resetCustomerInfoComponent = () => {
      showCustomerInfo.value = false;
    };

    const resetRideOverviewComponent = () => {
      showRideOverview.value = false;
    };

    const updateDateFromRideDetails = ({ dateOption: newDateOption, reservationDate }) => {
      dateOption.value = newDateOption;
      rideDateTime.value = reservationDate;
    };

    const updateDistanceDuration = ({ distance: dist, duration: dur }) => {
      distance.value = dist;
      duration.value = dur;
      showFareTypes.value = true;
    };

    const handleCalculateRoute = ({
      pickup: start,
      pickupCity: pickCity,
      pickupSublocality: pickSublocality,
      destination: dest,
      destinationCity: destCity,
      destinationSublocality: destSubLocality,
      destinationTwo: two,
      destinationTwoCity: twoCity,
      destinationTwoSublocality: twoSubLocality,
      destinationThree: three,
      destinationThreeCity: threeCity,
      destinationThreeSublocality: threeSubLocality,
    }) => {
      pickup.value = start;
      pickupCity.value = pickCity;
      pickupSublocality.value = pickSublocality;
      destination.value = dest;
      destinationCity.value = destCity;
      destinationSublocality.value = destSubLocality;
      destinationTwo.value = two;
      destinationTwoCity.value = twoCity;
      destinationTwoSublocality.value = twoSubLocality;
      destinationThree.value = three;
      destinationThreeCity.value = threeCity;
      destinationThreeSublocality.value = threeSubLocality;

      showMap.value = true;
      showFareTypes.value = true;
      nextTick().then(() => {
          const mapElement = document.querySelector('[data-map]');
          mapElement?.scrollIntoView({ behavior: 'smooth' });
      });
    };

    const handleFareSelected = ({ type, price }) => {
      selectedFareType.value = type;
      calculatedPrice.value = price;
      showCustomerInfo.value = true;

      nextTick().then(() => {
        const customerInfoElement = document.querySelector('[data-customer-info]');
        customerInfoElement?.scrollIntoView({ behavior: 'smooth' });
      });
    };

    const handleCustomerInfo = (info) => {
      customerInfo.value = info; // Store customer info so it persists
      showRideOverview.value = true;

      nextTick().then(() => {
        const rideOverviewElement = document.querySelector('[data-ride-overview]');
        rideOverviewElement?.scrollIntoView({ behavior: 'smooth' });
      });
    };

    const customerInfo = ref({
      name: '',
      phoneNumber: '',
      remarks: ''
    });

    const updateCustomerInfo = (newInfo) => {
      customerInfo.value = { ...newInfo };
    };

    // Functie om ride details bij te werken op basis van event van RideDetailsComponent
    const updateRideDetails = (options) => {
      hasBaggage.value = options.hasBaggage;
      luggage.value.large = options.largeLuggageCount;
      luggage.value.small = options.smallLuggageCount;
      passengerCount.value = options.passengerCount;
    };

    const loadMaps = () => {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.maps) {
          googleLoaded.value = true;
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBL90RdFndjyXMQM-dzSqs4C4hZw3m7ts0&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          googleLoaded.value = true;
          resolve();
        };
        script.onerror = (error) => reject(error);
        document.head.appendChild(script);
      });
    };

    const isReservation = computed(() => {
      return dateOption.value !== 'asap'; // True als het geen 'asap' is
    });

    const pickupTime = computed(() => {
      if (dateOption.value === 'asap') {
        // Huidige datum en tijd in dd-mm-yyyy hh:mm formaat
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Maanden beginnen bij 0
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}`;
      }
      // Gebruik de vooraf bepaalde datum en tijd
      return rideDateTime.value;
    });

    onMounted(() => loadMaps());

    return {
      googleLoaded,
      pickup,
      pickupCity,
      pickupSublocality,
      destination,
      destinationCity,
      destinationSublocality,
      destinationTwo,
      destinationTwoCity,
      destinationTwoSublocality,
      destinationThree,
      destinationThreeCity,
      destinationThreeSublocality,
      handleCalculateRoute,
      handleFareSelected,
      distance,
      duration,
      updateDistanceDuration,
      selectedFareType,
      rideDateTime,
      dateOption,
      pickupTime,
      customerInfo,
      updateCustomerInfo,
      handleCustomerInfo,
      calculatedPrice,
      showMap,
      showFareTypes,
      showCustomerInfo,
      showRideOverview,
      mapComponent,
      resetFareTypeComponent,
      resetMapComponent,
      resetCustomerInfoComponent,
      resetRideOverviewComponent,
      updateDateFromRideDetails,
      luggage,
      passengerCount,
      hasBaggage,
      updateRideDetails,
      isReservation
    };
  },
};
</script>

<style scoped>
.tba-scale-wrapper {
    transform: scale(0.85);
    transform-origin: top left;
    width: 117.65%;
    height: 117.65%;
    background-color: #f5f5f5; /* Mobiele achtergrond */
}
</style>
