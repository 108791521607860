<template>
  <div
    :class="[
      'tba-p-6 tba-rounded-lg tba-shadow-md tba-mx-auto tba-ride-overview-container',
      showSuccessMessage ? 'tba-bg-primary-bg tba-text-accent' : 'tba-bg-secondary-bg tba-text-text-primary'
    ]"
    data-ride-overview
  >
    <div v-if="!isLoading && !showSuccessMessage && !isError">
      <h3 class="tba-text-xl tba-font-semibold tba-mb-6 tba-text-text-primary">
        {{ translations[currentLanguage]?.RideOverviewComponent?.sectionTitle }}
      </h3>

      <div class="tba-mb-4">
        <p><strong>{{ translations[currentLanguage]?.RideOverviewComponent?.pickupAddressLabel }}</strong> <span>{{ pickupAddress }}</span></p>
        <p><strong>{{ translations[currentLanguage]?.RideOverviewComponent?.destinationAddressLabel }}</strong> <span>{{ destinationAddress }}</span></p>
        <p v-if="destinationTwo"><strong>{{ translations[currentLanguage]?.RideOverviewComponent?.destinationTwoLabel }}</strong> {{ destinationTwo }}</p>
        <p v-if="destinationThree"><strong>{{ translations[currentLanguage]?.RideOverviewComponent?.destinationThreeLabel }}</strong> {{ destinationThree }}</p>

        <div class="tba-flex tba-justify-between tba-my-2">
          <p>
            <strong>{{ translations[currentLanguage]?.RideOverviewComponent?.distanceLabel }}</strong>
            <span>{{ distance }} {{ translations[currentLanguage]?.RideOverviewComponent?.distanceUnit }}</span>
          </p>
          <p>
            <strong>{{ translations[currentLanguage]?.RideOverviewComponent?.durationLabel }}</strong>
            <span>{{ duration }} {{ translations[currentLanguage]?.RideOverviewComponent?.durationUnit }}</span>
          </p>
        </div>
        <p><strong>{{ translations[currentLanguage]?.RideOverviewComponent?.fareTypeLabel }}</strong> <span>{{ fareType }}</span></p>
        <p><strong>{{ translations[currentLanguage]?.RideOverviewComponent?.dateAndTimeLabel }}</strong> <span>{{ dateAndTime }}</span></p>
        <p><strong>{{ translations[currentLanguage]?.RideOverviewComponent?.passengerCountLabel }}</strong> <span>{{ passengerCount }}</span></p>
        <p><strong>{{ translations[currentLanguage]?.RideOverviewComponent?.luggageDetailsLabel }}</strong> <span>{{ luggageDetails }}</span></p>
      </div>

      <div class="tba-mb-4">
        <p><strong>{{ translations[currentLanguage]?.RideOverviewComponent?.customerNameLabel }}</strong> <span>{{ customerInfo.name }}</span></p>
        <p><strong>{{ translations[currentLanguage]?.RideOverviewComponent?.customerPhoneLabel }}</strong> <span>{{ customerInfo.phoneNumber }}</span></p>
        <p><strong>{{ translations[currentLanguage]?.RideOverviewComponent?.customerRemarksLabel }}</strong> <span>{{ customerInfo.remarks }}</span></p>
      </div>

      <div class="tba-flex tba-justify-between tba-items-center tba-border-t tba-pt-4">
        <strong class="tba-text-lg">{{ translations[currentLanguage]?.RideOverviewComponent?.priceLabel }}</strong>
        <span class="tba-text-lg tba-text-text-primary tba-font-semibold">{{ formattedPrice }}</span>
      </div>
      
      <button
        type="button"
        class="tba-w-full tba-py-3 tba-mt-4 tba-bg-secondary-button tba-text-text-on-primary tba-font-semibold tba-rounded-md tba-hover:bg-secondary-button-hover tba-transition"
        @click="bookRide"
      >
        {{ translations[currentLanguage]?.RideOverviewComponent?.bookRideButton }}
      </button>
    </div>

    <div v-if="isLoading" class="tba-text-center tba-mt-4">
      <span>{{ translations[currentLanguage]?.RideOverviewComponent?.processingBooking }}</span>
      <div class="loader"></div>
    </div>

    <div v-if="showSuccessMessage" class="fixed inset-0 tba-bg-text-on-primary tba-z-50 tba-flex tba-flex-col tba-justify-center tba-items-center tba-p-4">
      <h3 class="tba-text-3xl tba-font-bold tba-text-success-text tba-mb-6">
        {{ translations[currentLanguage]?.RideOverviewComponent?.successTitle }}👍
      </h3>
      
      <div class="tba-bg-success-bg tba-rounded-lg tba-shadow-lg tba-p-6 tba-w-full tba-max-w-md">
        <h4 class="tba-text-lg tba-font-semibold tba-mb-4 text-center">
          {{ translations[currentLanguage]?.RideOverviewComponent?.overviewTitle }}
        </h4>
        <p class="tba-text-sm tba-mb-2">
          <strong>{{ translations[currentLanguage]?.RideOverviewComponent?.pickupAddressLabel }}</strong>
          {{ pickupAddress }}
        </p>
        <p class="tba-text-sm tba-mb-2">
          <strong>{{ translations[currentLanguage]?.RideOverviewComponent?.destinationAddressLabel }}</strong>
          {{ destinationAddress }}
        </p>
        <p v-if="destinationTwo" class="tba-text-sm tba-mb-2">
          <strong>{{ translations[currentLanguage]?.RideOverviewComponent?.destinationTwoLabel }}</strong>
          {{ destinationTwo }}
        </p>
        <p v-if="destinationThree" class="tba-text-sm tba-mb-2">
          <strong>{{ translations[currentLanguage]?.RideOverviewComponent?.destinationThreeLabel }}</strong>
          {{ destinationThree }}
        </p>
        <p class="tba-text-sm tba-mb-2">
          <strong>{{ translations[currentLanguage]?.RideOverviewComponent?.dateAndTimeLabel }}</strong>
          {{ dateAndTime }}
        </p>
        <p class="tba-text-lg tba-font-semibold tba-mb-4 text-center">
          <strong>{{ translations[currentLanguage]?.RideOverviewComponent?.priceLabel }}</strong>
          {{ formattedPrice }}
        </p>
        <p class="tba-text-xs tba-text-gray-500 text-center">
          {{ translations[currentLanguage]?.RideOverviewComponent?.screenshotInfo }}
        </p>
      </div>

      <div class="tba-text-sm tba-text-gray-700 tba-mt-6">
        <p>{{ translations[currentLanguage]?.RideOverviewComponent?.nextSteps }}</p>
        <ul class="tba-list-disc tba-list-inside">
          <li>{{ translations[currentLanguage]?.RideOverviewComponent?.stepOne }}</li>
          <li>{{ translations[currentLanguage]?.RideOverviewComponent?.stepTwo }}</li>
          <li>{{ translations[currentLanguage]?.RideOverviewComponent?.stepThree }}</li>
        </ul>
      </div>

    </div>


    <div v-if="isError" class="tba-text-center tba-text-danger">
      <p>{{ translations[currentLanguage]?.RideOverviewComponent?.errorBooking }}</p>
    </div>

    <small v-if="!isLoading && !showSuccessMessage" class="tba-text-xs tba-text-text-secondary tba-mt-4 tba-block">
      {{ translations[currentLanguage]?.RideOverviewComponent?.termsAndConditions }}
      <a href="https://book-a-taxi.nl/algemene-voorwaarden.php" target="_blank" class="tba-text-blue-500 tba-underline">
        {{ translations[currentLanguage]?.RideOverviewComponent?.termsAndConditionsLink }}
      </a>.
    </small>
  </div>
</template>



<script>
import { computed, reactive, ref, inject } from 'vue';

export default {
  props: {
    pickupAddress: String,
    pickupCity: String,
    pickupSublocality: String,
    destinationAddress: String,
    destinationCity: String,
    destinationSublocality: String,
    destinationTwo: String,
    destinationTwoCity: String,
    destinationTwoSublocality: String,
    destinationThree: String,
    destinationThreeCity: String,
    destinationThreeSublocality: String,
    distance: [String, Number],
    duration: [String, Number],
    fareType: String,
    dateAndTime: String,
    price: [String, Number],
    customerInfo: {
      type: Object,
      default: () => ({}),
    },
    luggage: {
      type: Object,
      default: () => ({ large: 0, small: 0 }),
    },
    passengerCount: {
      type: Number,
      default: 1,
    },
    hasBaggage: {
      type: Boolean,
      default: false,
    },
    isReservation: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {

    const currentLanguage = inject("currentLanguage"); // Huidige taal ophalen
    const translations = inject("translations"); // Vertalingen ophalen
    const isLoading = ref(false);
    const showSuccessMessage = ref(false);
    const isError = ref(false);
    const successMessage = reactive({
      title: '',
      body: '',
    });

    const formattedPrice = computed(() => {
      return typeof props.price === 'number' ? `€ ${props.price.toFixed(2)}` : props.price;
    });

    const luggageDetails = computed(() => {
      if (props.luggage.large === 0 && props.luggage.small === 0) {
        return 'Geen';
      }
      const details = [];
      if (props.luggage.large > 0) details.push(`${props.luggage.large}x grote bagage`);
      if (props.luggage.small > 0) details.push(`${props.luggage.small}x kleine bagage`);
      return details.join(', ');
    });

    const combinedRemarks = computed(() => {
      const remarks = props.customerInfo.remarks || '';
      const details = [];

      if (props.luggage.large > 0) details.push(`${props.luggage.large}x grote bagage`);
      if (props.luggage.small > 0) details.push(`${props.luggage.small}x kleine bagage`);
      details.push(`${props.passengerCount} pax`);

      return `${remarks}\n${details.join(', ')}`;
    });

    const prepareBookingData = () => {
      return {
        pickup: props.pickupAddress,
        pickupCity: props.pickupCity,
        pickupSublocality: props.pickupSublocality,
        destination: props.destinationAddress,
        destinationCity: props.destinationCity,
        destinationSublocality: props.destinationSublocality,
        destinationTwo: props.destinationTwo,
        destinationTwoCity: props.destinationTwoCity,
        destinationTwoSublocality: props.destinationTwoSublocality,
        destinationThree: props.destinationThree,
        destinationThreeCity: props.destinationThreeCity,
        destinationThreeSublocality: props.destinationThreeSublocality,
        pickupTime: props.dateAndTime,
        customerName: props.customerInfo.name,
        customerNumber: props.customerInfo.phoneNumber,
        price: props.price,
        distance: props.distance,
        duration: props.duration,
        comment: combinedRemarks.value,
        paxCount: props.fareType === 'bus' ? '1-8' : '1-4',
        source: 'book-a-taxi.nl',
        isReservation: props.isReservation
      };
    };

    const storeBooking = async (bookingData) => {
      const apiUrl = 'https://wat-kost-een-taxi.com/api/bookings';
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bookingData),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    };

    const updateUIAfterBooking = (totalPrice) => {
      const currentLang = 'nl';
      const messages = {
        nl: {
          title: 'Boeking Succesvol',
          body: `Bedankt voor uw boeking! Uw prijs is €${totalPrice}.`,
        },
      };
      successMessage.title = messages[currentLang].title;
      successMessage.body = messages[currentLang].body;
      showSuccessMessage.value = true;
    };

    const bookRide = async () => {
      isLoading.value = true;
      isError.value = false;

      try {
        const bookingData = prepareBookingData();
        await storeBooking(bookingData);
        updateUIAfterBooking(props.price);
      } catch (error) {
        console.error('Booking failed:', error);
        isError.value = true;
      } finally {
        isLoading.value = false;
      }
    };

    return {
      isLoading,
      showSuccessMessage,
      isError,
      successMessage,
      formattedPrice,
      luggageDetails,
      combinedRemarks,
      bookRide,
      currentLanguage,
      translations,
    };
  },
};
</script>


<style scoped>
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Zorg ervoor dat de succesboodschap de hele component overneemt */
.success-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 50;
  padding: 1rem;
}


.tba-shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.tba-list-disc {
  list-style-type: disc;
  margin: 0.5rem 0;
  padding-left: 1.5rem;
}


</style>
